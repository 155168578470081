import { EntityGroup, Entity } from '@/types/entities';
import { useMemo, useEffect, useState, useCallback } from 'react';
import { useNavigate } from '@tanstack/react-router';
import {
  GeoJsonLayer,
  PickingInfo,
  TextLayer,
  WebMercatorViewport,
} from 'deck.gl';
import { Map } from '@vis.gl/react-google-maps';
import { FeatureCollection, Feature, Point } from 'geojson';
import { DeckGL, MapViewState } from 'deck.gl';
import { CollisionFilterExtension } from '@deck.gl/extensions';
import Button from './core/Button';
import { cn } from '@/utils/classnames';
import { MapIcon, SatelliteIcon } from 'lucide-react';
import { Spinner } from './Spinner';
import { WorkOrder } from '@/hooks/useWorkOrders';
import WorkOrderMapFilter from './WorkOrderMapFilter';
import { useEntities } from '@/hooks/useEntities';

function getInitialViewState(entities: Entity[]): MapViewState | null {
  if (entities.length === 0) {
    return {
      longitude: 16,
      latitude: 62,
      zoom: 5,
    };
  }

  const bounds = new google.maps.LatLngBounds();
  entities.forEach((entity) => {
    if (entity.location) {
      bounds.extend(
        new google.maps.LatLng(entity.location.lat, entity.location.lng),
      );
    }
  });

  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  const viewport = new WebMercatorViewport({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const { longitude, latitude, zoom } = viewport.fitBounds(
    [
      [sw.lng(), sw.lat()],
      [ne.lng(), ne.lat()],
    ],
    { padding: 240, maxZoom: 18 },
  );

  return { longitude, latitude, zoom, pitch: 0, bearing: 0 };
}

interface EntityMapProps {
  groups?: EntityGroup[];
  entities?: Entity[];
  workorders?: WorkOrder[];
}

export function EntityMap({ groups, entities, workorders }: EntityMapProps) {
  // This map component handles just one of the props at the time.
  // For example, if both groups and workorders are provided, only groups can be used.

  const navigate = useNavigate();
  const { data: allEntities } = useEntities();
  const [isVisible, setIsVisible] = useState(false);
  const [viewState, setViewState] = useState<MapViewState | null>(null);
  const [mapType, setMapType] = useState<google.maps.MapTypeId>(
    google.maps.MapTypeId.ROADMAP,
  );
  const [filteredWorkorders, setFilteredWorkorders] = useState<WorkOrder[]>([]);

  const effectiveWorkorders = useMemo(() => {
    if (!workorders) {
      return;
    }
    return filteredWorkorders.length === 0 ? workorders : filteredWorkorders;
  }, [workorders, filteredWorkorders]);

  const workorderEntities = useMemo(() => {
    if (!effectiveWorkorders || !allEntities) {
      return [];
    }

    const effectiveWorkorderEntities = new Set(
      effectiveWorkorders.flatMap((workorder) => workorder.entities),
    );

    return allEntities
      .filter((entity) => effectiveWorkorderEntities.has(entity.id))
      .map((entity) => ({
        ...entity,
        workorders: effectiveWorkorders.filter((workorder) =>
          workorder.entities.includes(entity.id),
        ),
      }));
  }, [effectiveWorkorders, allEntities]);

  const selectedEntities = useMemo(() => {
    if (groups) {
      return groups.flatMap((group) => group.entities || []);
    }
    if (workorders && workorderEntities) {
      return workorderEntities;
    }
    return entities || [];
  }, [groups, entities, workorders, workorderEntities]);

  const geoJsonData: FeatureCollection = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: selectedEntities.map((entity) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [entity.location?.lng || 0, entity.location?.lat || 0],
        },
        properties: {
          id: entity.id,
          name: entity.full_name,
        },
      })),
    };
  }, [selectedEntities]);

  const onClick = useCallback(
    (info: PickingInfo) => {
      navigate({
        to: '/entities/$entityId',
        params: { entityId: info.object.properties.id },
      });
    },
    [navigate],
  );

  const layers = useMemo(() => {
    return [
      new GeoJsonLayer({
        id: 'dot-layer',
        data: geoJsonData,
        filled: true,
        getFillColor: [247, 221, 67],
        pointRadiusUnits: 'pixels',
        getPointRadius: 7,
        getLineWidth: 1,
        getLineColor: [0, 0, 0, 255],
        pickable: true,
        onClick: onClick,
      }),
      new TextLayer({
        id: 'text-layer',
        data: geoJsonData.features,
        getText: (feature: Feature) => feature.properties?.name || '',
        getPosition: (feature: Feature<Point>) =>
          feature.geometry.coordinates as [number, number],
        characterSet: 'auto',
        getSize: 12,
        getColor: [0, 0, 0, 255],
        getTextAnchor: 'middle',
        getAlignmentBaseline: 'center',
        backgroundPadding: [8, 4, 8, 4],
        background: true,
        getBorderColor: [0, 0, 0, 255],
        getBorderWidth: 1,
        getPixelOffset: [0, -20],
        visible: isVisible,
        pickable: true,
        onClick: onClick,
        collisionTestProps: { sizeScale: 3 },
        extensions: [new CollisionFilterExtension()],
      }),
    ];
  }, [geoJsonData, isVisible, onClick]);

  useEffect(() => {
    const newViewState = getInitialViewState(selectedEntities);
    if (newViewState) {
      setViewState(newViewState);
    }
  }, [selectedEntities]);

  useEffect(() => {
    if (viewState) {
      setIsVisible(viewState.zoom > 15);
    }
  }, [viewState]);

  if (!viewState) {
    return (
      <div className="flex size-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="relative size-full">
      <MapTypeControllers setMapType={setMapType} mapType={mapType} />
      {workorders && (
        <WorkOrderMapFilter
          workorders={workorders}
          value={filteredWorkorders}
          onChange={setFilteredWorkorders}
        />
      )}
      <DeckGL
        layers={layers}
        controller={true}
        initialViewState={viewState}
        onViewStateChange={({ viewState }) =>
          setViewState(viewState as MapViewState)
        }
        style={{ position: 'relative' }}
      >
        <Map mapId="d7bdd6a0e6a67a1e" mapTypeId={mapType} />
      </DeckGL>
    </div>
  );
}

function MapTypeControllers({
  mapType,
  setMapType,
}: {
  mapType: google.maps.MapTypeId;
  setMapType: (mapType: google.maps.MapTypeId) => void;
}) {
  return (
    <div className="absolute left-4 top-4 z-10 flex gap-2">
      <Button
        variant="filled"
        startIcon={<MapIcon className="size-5" />}
        className={cn(
          mapType === google.maps.MapTypeId.ROADMAP
            ? 'bg-primary-500'
            : 'bg-background-100 text-primary-500 hover:text-contrast-primary',
        )}
        onClick={() => setMapType(google.maps.MapTypeId.ROADMAP)}
      >
        Karta
      </Button>
      <Button
        variant="filled"
        startIcon={<SatelliteIcon className="size-5" />}
        className={cn(
          mapType === google.maps.MapTypeId.SATELLITE
            ? 'bg-primary-500'
            : 'bg-background-100 text-primary-500 hover:text-contrast-primary',
        )}
        onClick={() => setMapType(google.maps.MapTypeId.SATELLITE)}
      >
        Satellit
      </Button>
    </div>
  );
}
