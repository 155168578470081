import { ApiEntitySchemaExtra } from '@allbin/mobilix-api-client';
import { Projection, proj } from './math';

/** Utility to format number. The value will be formatted in the order: 1.round_to_decimals, 2. toFixed, 3. grouping and all options can be mixed. */
export const formatNumber = (
  value: number,
  opts: ApiEntitySchemaExtra['number_options'] = {},
): string => {
  const { grouping = true, round_to_decimals, decimals } = opts;

  if (typeof decimals === 'number' && (decimals < 0 || decimals > 20)) {
    throw new Error('decimals is expected to be in range 1 to 20.');
  }

  let formattedValue = value.toString();
  let rounded = value;

  if (typeof round_to_decimals === 'number') {
    rounded = round(value, round_to_decimals);
    formattedValue = rounded.toString();
  }

  if (typeof decimals === 'number') {
    const decimalPointSplit = formattedValue.split('.');

    formattedValue =
      decimals === 0
        ? decimalPointSplit[0]
        : decimalPointSplit[0] +
          '.' +
          (
            (decimalPointSplit[1] ? decimalPointSplit[1] : '') +
            '00000000000000000000'
          ).substring(0, decimals);
  }

  if (grouping) {
    const isNegative = value < 0;
    const decimalPointSplit = formattedValue.replace('-', '').split('.');

    let str_arr = decimalPointSplit[0].split('');
    let str = '';
    while (str_arr.length > 3) {
      str =
        ' ' +
        str_arr[str_arr.length - 3] +
        str_arr[str_arr.length - 2] +
        str_arr[str_arr.length - 1] +
        str;
      str_arr = str_arr.slice(0, str_arr.length - 3);
    }
    const joined_arr = str_arr.join('');
    formattedValue = joined_arr.length > 0 ? joined_arr + str : str;

    if (isNegative) {
      //Add minus sign if negative.
      formattedValue = '-' + formattedValue;
    }
    // If decimal exist, concat the values after the decimal
    if (decimalPointSplit[1]) {
      formattedValue = `${formattedValue}.${decimalPointSplit[1]}`;
    }
  }

  return formattedValue.length > 0 ? formattedValue : rounded.toString();
};

export const round = (val: number, decimals: number = 0): number => {
  const p = Math.pow(10, decimals);
  const n = val * p * (1 + Number.EPSILON);
  return Math.round(n) / p;
};

export const locationPropertyToString = (
  location: [number, number],
  toProjection: Projection,
  fromProjection: Projection = 'WGS84',
  precision?: number,
): string => {
  if (typeof precision !== 'number') {
    if (toProjection === 'WGS84') {
      precision = 5;
    } else if (toProjection === 'EPSG:3021') {
      precision = 0;
    }
  }
  if (toProjection !== fromProjection) {
    location = proj(fromProjection, toProjection, location);
  }
  return `${location[0].toFixed(precision)}, ${location[1].toFixed(precision)}`;
};

export const readableProjection = (projection: Projection): string => {
  switch (projection) {
    case 'EPSG:3006': {
      return 'SWEREF99';
    }
    case 'EPSG:3021': {
      return 'RT90';
    }
    case 'WGS84': {
      return 'WGS84';
    }

    default: {
      console.warn(
        `Unknown projection to make human readable '${
          projection as unknown as string
        }'.`,
      );
      return projection;
    }
  }
};
