import {
  Navigate,
  createFileRoute,
  useNavigate,
  useRouter,
} from '@tanstack/react-router';
import { z } from 'zod';
import { IconNavArrowLeft, IconNavArrowRight, IconXmark } from '@allbin/icons';
import { useEntity } from '../../../../hooks/useEntities';
import { useEntitySchema } from '../../../../hooks/useEntitySchema';
import { useCallback, useMemo } from 'react';
import IconButton from '@/components/core/IconButton';
import { useConfig } from '../../../../hooks/useConfig';
import { ErrorPage } from '../../../../components/ErrorPage';
import { Spinner } from '../../../../components/Spinner';
import { cn } from '../../../../utils/classnames';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

const imageSearchSchema = z.object({
  image: z.string().optional(),
});

export const Route = createFileRoute('/_layout/entities/$entityId/images')({
  component: EntityImages,
  validateSearch: (search) => imageSearchSchema.parse(search),
});

function EntityImages() {
  const { history } = useRouter();
  const navigate = useNavigate();
  const { cdnUrl } = useConfig();

  const { entityId } = Route.useParams();

  const schema = useEntitySchema();
  const entity = useEntity(entityId);

  const images = useMemo(
    () =>
      (schema.data?.definition.properties || [])
        .filter((property) => property.type === 'photo')
        .map((property) => ({
          name: property.name,
          imageId: entity.data?.properties[property.key] as string,
        }))
        .filter((image) => !!image.imageId),
    [entity.data?.properties, schema.data?.definition.properties],
  );

  const { image } = Route.useSearch();

  const currentIndex = useMemo(
    () => images.findIndex((i) => i.imageId === image),
    [images, image],
  );

  const currentImage = useMemo(() => {
    if (currentIndex === -1) {
      return undefined;
    }
    return images[currentIndex];
  }, [images, currentIndex]);

  const prev = useCallback(() => {
    navigate({
      replace: true,
      search: {
        image:
          currentIndex === 0
            ? images[images.length - 1].imageId
            : images[currentIndex - 1].imageId,
      },
    });
  }, [currentIndex, images, navigate]);

  const next = useCallback(() => {
    navigate({
      replace: true,
      search: { image: images[(currentIndex + 1) % images.length].imageId },
    });
  }, [currentIndex, images, navigate]);

  if (schema.isLoading || entity.isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  if (images.length === 0) {
    return <ErrorPage error="Inga bilder hittades" />;
  }

  if (!image) {
    return <Navigate replace search={{ image: images[0].imageId }} />;
  }

  if (!currentImage) {
    return <ErrorPage error="Bilden kunde inte hittas" />;
  }

  return (
    <div className="relative flex size-full items-center justify-center">
      <div className="absolute inset-4 flex items-center justify-between">
        <IconButton
          variant="filled"
          round
          size="lg"
          icon={<IconNavArrowLeft className="size-8" />}
          className="z-10 shadow-lg"
          onClick={prev}
        />
        <IconButton
          variant="filled"
          round
          size="lg"
          icon={<IconNavArrowRight className="size-8" />}
          className="z-10 shadow-lg"
          onClick={next}
        />
      </div>
      <div className="absolute bottom-4 z-10 mx-auto flex items-center justify-center gap-4 rounded-full bg-gray-800/20 px-4 py-2 backdrop-blur-sm">
        {images.map((image) => (
          <button
            key={image.imageId}
            className={cn(
              'z-10 size-4 rounded-full border-2 border-white shadow',
              image.imageId === currentImage.imageId && 'bg-white',
            )}
            onClick={() =>
              navigate({
                search: { image: image.imageId as string },
                replace: true,
              })
            }
          />
        ))}
      </div>
      <IconButton
        variant="filled"
        round
        size="lg"
        icon={<IconXmark className="size-6" />}
        className="absolute right-4 top-4 z-10"
        onClick={() => history.back()}
      />
      <TransformWrapper centerOnInit>
        <TransformComponent wrapperClass="!size-full bg-gray-800">
          <div className="relative">
            <div className="absolute left-0 top-0 flex w-full justify-center p-2">
              <span className="rounded-md bg-gray-700 p-1 px-2 text-gray-50">
                {currentImage.name}
              </span>
            </div>
            <img src={`${cdnUrl}/${currentImage.imageId}/lg`} />
          </div>
        </TransformComponent>
      </TransformWrapper>
    </div>
  );
}
