import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useWorkOrder } from '../../../../hooks/useWorkOrders';
import { Spinner } from '../../../../components/Spinner';
import { ErrorPage } from '../../../../components/ErrorPage';
import { FormattedMessage } from 'react-intl';
import { FormEvent, useCallback, useMemo, useRef, useState } from 'react';
import Button from '@/components/core/Button';
import { useWorkOrderCreateComment } from '../../../../hooks/useWorkOrderEvents';
import { useCreateAttachment } from '../../../../hooks/useAttachments';
import { ApiAttachment, ApiCommentEvent } from '@allbin/mobilix-api-client';
import { PageHeader } from '../../../../components/PageHeader';
import Input from '@/components/core/Input';

export const Route = createFileRoute(
  '/_layout/workorders/$workorderId/comment',
)({
  component: WorkOrderCommentForm,
});

function WorkOrderCommentForm() {
  const navigate = useNavigate();
  const { workorderId } = Route.useParams();
  const workorder = useWorkOrder(workorderId);

  const createAttachment = useCreateAttachment();
  const commentWorkOrder = useWorkOrderCreateComment(workorderId);

  const [comment, setComment] = useState('');
  const [image, setImage] = useState<File | null>(null);

  const isLoading = useMemo(
    () =>
      workorder.isLoading ||
      commentWorkOrder.isPending ||
      createAttachment.isPending,
    [
      workorder.isLoading,
      commentWorkOrder.isPending,
      createAttachment.isPending,
    ],
  );

  const preview = useMemo(() => {
    if (!image) return null;
    return URL.createObjectURL(image);
  }, [image]);

  const canSave = useMemo(
    () => comment.trim().length > 0 || !!image,
    [comment, image],
  );

  const imageUploadInputRef = useRef<HTMLInputElement>(null);

  const handleImageSelect = useCallback(() => {
    imageUploadInputRef.current?.click();
  }, []);

  const handleFileUpload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const fileUploaded = e.target.files?.[0];
      if (!fileUploaded) {
        return;
      }
      setImage(fileUploaded);
    },
    [],
  );

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (!canSave) return;

      try {
        let attachment: ApiAttachment | undefined;
        if (image) {
          attachment = await createAttachment.mutateAsync(image);
        }

        const attachments: ApiCommentEvent['data']['attachments'] = attachment
          ? [
              {
                attachment: attachment.id,
                name: attachment.name,
                mime_type: attachment.mime_type,
              },
            ]
          : undefined;

        const event: ApiCommentEvent = {
          type: 'comment',
          data: {
            text: comment || undefined,
            attachments,
          },
        };

        await commentWorkOrder.mutateAsync({
          event,
        });

        navigate({
          to: '/workorders/$workorderId/events',
          params: { workorderId },
          replace: true,
        });
      } catch (e) {
        console.error('Handle error', e);
      }
    },
    [
      canSave,
      comment,
      commentWorkOrder,
      createAttachment,
      image,
      navigate,
      workorderId,
    ],
  );

  if (workorder.isLoading) {
    return (
      <div className="mt-16 flex w-full justify-center">
        <Spinner />
      </div>
    );
  }

  if (workorder.isError && !workorder.error.message.endsWith('404')) {
    console.error(workorder.error);

    return <ErrorPage error={workorder.error.message} />;
  }

  if (!workorder.data) {
    return (
      <ErrorPage
        title={
          <FormattedMessage defaultMessage="Kunde inte hitta några händelser" />
        }
        error={`Arbetsorder #${workorderId.substring(0, 6)} har inga händelser i systemet, eller så har du inte behörighet att se dem.`}
      />
    );
  }

  return (
    <>
      <PageHeader
        title={
          <>
            {workorder.data.title}
            <span className="ml-2 text-lg text-text-300">
              #{workorder.data.title_suffix}
            </span>
          </>
        }
        subtitle={
          <FormattedMessage defaultMessage="Kommentera på arbetsorder" />
        }
      />
      <form
        className="flex h-full flex-col items-center gap-4"
        onSubmit={(e) => handleSubmit(e)}
      >
        <div className="mt-4 flex w-full grow flex-col items-start gap-4 md:flex-row md:gap-16">
          <fieldset className="flex w-full flex-1 flex-col">
            <Input
              id="comment"
              type="multiline"
              rows={4}
              label={<FormattedMessage defaultMessage="Kommentar" />}
              placeholder="Skriv en kommentar"
              disabled={isLoading}
              value={comment}
              onChange={(e) => setComment(e.currentTarget.value)}
            />
          </fieldset>
          <fieldset className="flex w-full flex-1 flex-col gap-2">
            <label
              htmlFor="image"
              className="mb-1 block text-sm font-medium leading-6 text-primary-900"
            >
              <FormattedMessage defaultMessage="Bild" />
            </label>
            <input
              id="image"
              type="file"
              className="hidden"
              onChange={handleFileUpload}
              ref={imageUploadInputRef}
              accept="image/*"
            />
            {preview ? (
              <>
                <img
                  src={preview}
                  alt="Preview"
                  className="max-h-64 object-contain"
                />
                <Button onClick={() => setImage(null)} disabled={isLoading}>
                  <FormattedMessage defaultMessage="Ta bort bild" />
                </Button>
              </>
            ) : (
              <Button
                variant="filled"
                onClick={handleImageSelect}
                disabled={isLoading}
              >
                <FormattedMessage defaultMessage="Lägg till bild" />
              </Button>
            )}
          </fieldset>
        </div>
        <div className="grow" />
        <footer className="flex justify-end gap-4 bg-background-50 py-4">
          <Button
            onClick={() =>
              navigate({
                to: '/workorders/$workorderId/events',
                params: { workorderId },
              })
            }
            disabled={isLoading}
          >
            <FormattedMessage defaultMessage="Avbryt" />
          </Button>
          <Button
            type="submit"
            variant="filled"
            disabled={!canSave || isLoading}
          >
            <FormattedMessage defaultMessage="Spara" />
          </Button>
        </footer>
      </form>
    </>
  );
}
