import { UseControllerProps, useController } from 'react-hook-form';
import { FormValues } from '../EntityPropertyList';
import Input from '@/components/core/Input';
import { useCallback, useMemo, useState } from 'react';
import { IconPlus, IconXmark } from '@allbin/icons';
import Button from '@/components/core/Button';
import { useIntl } from 'react-intl';

export function ArrayStringInput(props: UseControllerProps<FormValues>) {
  const intl = useIntl();
  const [text, setText] = useState('');
  const { field } = useController(props);
  const value = useMemo(() => field.value as string[] | null, [field.value]);

  const addItem = useCallback(
    (item: string) => {
      if (!item) return;

      if (!value?.includes(item)) {
        field.onChange([...(value || []), item]);
      }
      setText('');
    },
    [field, value],
  );

  const removeItem = useCallback(
    (index: number) => {
      if (!value) return;

      field.onChange(value.filter((_, j) => index !== j));
    },
    [field, value],
  );

  return (
    <div className="flex max-w-full grow flex-col gap-2">
      <Input
        id={props.name}
        className="w-full pr-10"
        type="text"
        placeholder={intl.formatMessage({ defaultMessage: 'Nytt värde' })}
        value={text}
        endAdornment={
          text && (
            <button type="button" onClick={() => addItem(text)}>
              <IconPlus className="size-5" />
            </button>
          )
        }
        onChange={(e) => setText(e.target.value)}
        onKeyDown={(e) => {
          if (e.key !== 'Enter') {
            return;
          }
          addItem(text);
          e.preventDefault();
          e.stopPropagation();
        }}
      />
      {value && value.length > 0 && (
        <div className="flex w-full flex-wrap items-center justify-end gap-2">
          {value.map((item, i) => (
            <div
              key={item}
              className="flex items-center gap-1 overflow-hidden text-ellipsis rounded bg-blue-100 px-2 py-1"
            >
              <span className="overflow-hidden text-ellipsis">{item}</span>
              <Button
                variant="ghost"
                className="min-h-0 !p-1"
                startIcon={<IconXmark />}
                onClick={() => {
                  removeItem(i);
                }}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
