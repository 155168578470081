import { useQuery } from '@tanstack/react-query';
import { useApiClient } from './useApiClient';
import { ReviverFn, reviveCheckIn, reviveMetadata } from '../utils/revivers';
import { ApiEntity, ApiEntityEvent } from '@allbin/mobilix-api-client';
import { Entity } from '../types/entities';
import { Metadata } from '../types/metadata';

export type EntityEvent = Omit<ApiEntityEvent, 'meta'> & {
  meta: Metadata;
  sourceType: 'entity';
};

export function useEntities() {
  const apiClient = useApiClient();

  return useQuery({
    queryKey: ['entities', 'list'],
    queryFn: async () => {
      try {
        const entities = await apiClient.entities.list();
        return entities.map(reviveEntity);
      } catch (error) {
        console.error('Error fetching entities', error);
        throw error;
      }
    },
  });
}

export function useEntitiesById(ids?: string[]) {
  const apiClient = useApiClient();

  return useQuery({
    queryKey: ['entities', ids],
    queryFn: async () => {
      try {
        const entities = await apiClient.entities.getMany(ids || []);
        return entities.map(reviveEntity);
      } catch (error) {
        console.error('Error fetching entities', error);
        throw error;
      }
    },
    enabled: !!ids,
  });
}

export function useEntity(id: string) {
  const apiClient = useApiClient();
  return useQuery({
    queryKey: ['entity', id],
    queryFn: async () => {
      try {
        const entity = await apiClient.entities.get(id);
        return reviveEntity(entity);
      } catch (error) {
        console.error('Error fetching entity', error);
        throw error;
      }
    },
  });
}

export function useEntityEvents(id: string) {
  const apiClient = useApiClient();
  return useQuery({
    queryKey: ['entity', id, 'events'],
    queryFn: async () => {
      try {
        const events = await apiClient.entities.listEvents(id);
        return events.map(reviveEntityEvent);
      } catch (error) {
        console.error('Error fetching entity events', error);
        throw error;
      }
    },
  });
}

export const reviveEntity: ReviverFn<ApiEntity, Entity> = (apiEntity) => {
  const name = apiEntity.properties['meta.name'] as string;
  const entity_group = apiEntity.properties['meta.group_id'] as string;
  const stop_letter = apiEntity.properties['meta.code'] as string;

  return {
    ...apiEntity,
    meta: reviveMetadata(apiEntity.meta),
    entity_group,
    stop_letter,
    full_name: `${name} ${stop_letter}`,
    checkins: apiEntity.checkins
      ? {
          admin: apiEntity.checkins.admin.map(reviveCheckIn),
          contractor: apiEntity.checkins.contractor.map(reviveCheckIn),
        }
      : undefined,
    location: apiEntity.properties['meta.location']
      ? {
          lng: (apiEntity.properties['meta.location'] as number[])[0],
          lat: (apiEntity.properties['meta.location'] as number[])[1],
        }
      : undefined,
    properties: { ...apiEntity.properties, ...apiEntity.derived_properties },
  };
};

export const reviveEntityEvent: ReviverFn<ApiEntityEvent, EntityEvent> = (
  entityEvent,
) => ({
  ...entityEvent,
  meta: reviveMetadata(entityEvent.meta),
  sourceType: 'entity',
});
