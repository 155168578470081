import { useQuery } from '@tanstack/react-query';
import { useApiClient } from './useApiClient';
import { useAuth0 } from '@auth0/auth0-react';

export function useEntitySchema() {
  const { isAuthenticated } = useAuth0();
  const apiClient = useApiClient();

  return useQuery({
    queryKey: ['entitySchema', 'list'],
    queryFn: async () => {
      try {
        const entitySchema = await apiClient.entitySchemas.list();
        if (!entitySchema) {
          throw new Error('No entity schema found');
        }
        if (entitySchema.length === 0) {
          throw new Error('No entity schema found');
        }
        return entitySchema[0];
      } catch (error) {
        console.error('Error fetching entity schema', error);
        throw error;
      }
    },
    enabled: isAuthenticated,
  });
}
