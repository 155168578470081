import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

interface Config {
  baseUrl: string;
  cdnUrl: string;
  mapsApiKey: string;
  auth: {
    domain: string;
    clientId: string;
    audience: string;
  };
}

const defaultConfig: Config = {
  baseUrl: 'https://api.mobilix.dev.allbin.se',
  cdnUrl: 'https://storage.googleapis.com/mobilix-dev',
  mapsApiKey: 'AIzaSyC41eVEZZqvINbaA8sGpibU2ICX0UlUejo',
  auth: {
    domain: 'allbin.eu.auth0.com',
    clientId: '7kaD6bB7JeVe6iEmT7bpOtw9FN6MKoqP',
    audience: 'https://api.mobilix.dev.allbin.se',
  },
};

export const useConfig = () => {
  const query = useQuery<Config>({
    queryKey: ['config'],
    queryFn: async () => {
      const { data } = await axios.get('/config.json');
      return {
        ...defaultConfig,
        ...data,
        auth: {
          ...defaultConfig.auth,
          ...data.auth,
        },
      };
    },
  });

  return { ...defaultConfig, ...query.data, query };
};
