import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useEntitiesById, useEntity } from '../../../../hooks/useEntities';
import {
  WorkOrder,
  WorkOrderRequest,
  useWorkOrder,
  useWorkOrderUpdate,
} from '../../../../hooks/useWorkOrders';
import { PageHeader } from '../../../../components/PageHeader';
import { ErrorPage } from '../../../../components/ErrorPage';
import { FormattedMessage } from 'react-intl';
import Button from '@/components/core/Button';
import CheckBox from '@/components/core/Checkbox';
import { useEntitySchema } from '../../../../hooks/useEntitySchema';
import {
  EntityPropertyList,
  FormValues,
} from '../../../../components/EntityProp/EntityPropertyList';
import { Entity, EntityProps } from '@/types/entities';
import { ApiEntitySchema } from '@allbin/mobilix-api-client';
import { useForm, useWatch } from 'react-hook-form';
import { useCallback, useMemo, useEffect, useState } from 'react';
import { filterProperties } from '@/utils/entity_changeset';
import { EntityGalleryMap } from '@/components/EntityProp/EntityGalleryMap';
import { useImagePicker } from '@/hooks/useImagePicker';
import { useShowAttributes } from '@/hooks/useUserConfig';
import { useEntityFormStore } from '@/store/useEntityFormStore';
import { ApiEntityPropValue } from '@/types/entities';
import { ArrowLeftIcon } from 'lucide-react';
import { useEntityGroups } from '@/hooks/useEntityGroups';
import IconButton from '@/components/core/IconButton';
import { cn } from '@/utils/classnames';
import { collator } from '@/utils/collator';

export const Route = createFileRoute(
  '/_layout/workorders/$workorderId/entity/$entityId',
)({
  component: WorkOrderEntity,
});

function WorkOrderEntity() {
  const { workorderId, entityId } = Route.useParams();

  const workorder = useWorkOrder(workorderId);
  const entities = useEntitiesById(workorder.data?.entities);
  const entity = useEntity(entityId);
  const entitySchema = useEntitySchema();
  const groups = useEntityGroups(entities.data || []);
  const navigate = useNavigate();

  const group = useMemo(() => {
    if (!entity.data?.entity_group || !groups) {
      return undefined;
    }
    const group = groups.find(
      (group) => group.id === entity.data?.entity_group,
    );

    return (
      group?.entities.sort((a, b) =>
        collator.compare(a.stop_letter, b.stop_letter),
      ) || []
    );
  }, [groups, entity.data?.entity_group]);

  const entityWithChangeset = useMemo<Entity | undefined>(() => {
    if (!entity.data || !workorder.data) {
      return undefined;
    }

    const entityChangeset = workorder.data.entity_changesets[entity.data.id];

    return {
      ...entity.data,
      changeset_head:
        entityChangeset?.prev_changeset_id || entity.data.changeset_head,
      properties: entityChangeset?.properties || entity.data.properties,
    } as Entity;
  }, [entity, workorder.data]);

  if (workorder.isLoading || entity.isLoading) {
    return <div className="mt-16 flex w-full justify-center">Loading...</div>;
  }

  if (workorder.isError) {
    return <ErrorPage error={workorder.error.message} />;
  }

  if (entity.isError) {
    return <ErrorPage error={entity.error.message} />;
  }

  if (!entityWithChangeset || !entitySchema.data || !workorder.data) {
    return (
      <ErrorPage
        error={
          <FormattedMessage defaultMessage="Kan inte hitta arbetsordern eller hållplatsen" />
        }
      />
    );
  }

  return (
    <>
      <PageHeader
        title={entityWithChangeset.full_name}
        subtitle={`#${entityWithChangeset.entity_group}-${entityWithChangeset.stop_letter}`}
        action={
          <div className="flex h-full flex-wrap items-center justify-end gap-3">
            {group?.map((entity) => (
              <IconButton
                key={entity.id}
                variant="filled"
                color={
                  workorder.data.entity_changesets?.[entity.id]
                    ? 'green'
                    : 'entity'
                }
                icon={entity.stop_letter}
                round
                className={cn(
                  '!font-medium',
                  entity.id === entityId && 'border-4 border-primary-500',
                )}
                onClick={() =>
                  navigate({
                    to: '/workorders/$workorderId/entity/$entityId',
                    params: { workorderId, entityId: entity.id },
                  })
                }
              />
            ))}
          </div>
        }
      />
      {entity.data && entitySchema.data && (
        <EntityForm
          workorder={workorder.data}
          entity={entityWithChangeset}
          schema={entitySchema.data}
        />
      )}
    </>
  );
}

interface EntityFormProps {
  workorder: WorkOrder;
  entity: Entity;
  schema: ApiEntitySchema;
}

function toFormName(key: string) {
  return key.replace('.', '#');
}
function fromFormName(key: string) {
  return key.replace('#', '.');
}

function EntityForm({ workorder, entity, schema }: EntityFormProps) {
  const { entityId, workorderId } = Route.useParams();
  const navigate = useNavigate();
  const update = useWorkOrderUpdate();
  const { resetImages } = useImagePicker();
  const { toggleAttributes, showEditAttributes } = useShowAttributes();
  const [formKey, setFormKey] = useState(entityId);
  const [lastSaved, setLastSaved] = useState<string | null>(null);
  const { checkProperties, updateStorageProperty, deleteStorageProperty } =
    useEntityFormStore();

  useEffect(() => {
    resetImages();
    setFormKey(entityId);
  }, [resetImages, entityId]);

  const entityProperty = useMemo(() => {
    const getProperty = checkProperties(entity.properties, workorderId);
    return getProperty;
  }, [checkProperties, entity.properties, workorderId]);

  useEffect(() => {
    if (!entityProperty) {
      return;
    }
    setLastSaved(entityProperty.last_saved ?? null);
  }, [entityProperty]);

  const defaultValues = useMemo<FormValues>(() => {
    // convert . to # in keys to make it valid for react-hook-form
    return schema.definition.properties.reduce((acc, prop) => {
      acc[toFormName(prop.key)] = entityProperty?.data[prop.key] ?? null;
      return acc;
    }, {} as FormValues);
  }, [schema, entityProperty]);

  const { control, handleSubmit, formState, setValue, reset } =
    useForm<FormValues>({
      defaultValues,
    });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  // Checks every value change in the form
  const newFormValues = useWatch({
    control,
  });

  // Update the local storage property
  useEffect(() => {
    if (
      formKey !== entityId ||
      JSON.stringify(defaultValues) === JSON.stringify(newFormValues)
    ) {
      return;
    }

    const parsedProperties = Object.keys(newFormValues).reduce((acc, key) => {
      if (!key.startsWith('derived')) {
        acc[fromFormName(key)] = newFormValues[key] as ApiEntityPropValue;
      }
      return acc;
    }, {} as EntityProps);

    const entity = {
      ...entityProperty,
      data: parsedProperties,
    };
    const updatedProperty = updateStorageProperty(entity);
    setLastSaved(updatedProperty.last_saved);
  }, [
    newFormValues,
    updateStorageProperty,
    entityProperty,
    defaultValues,
    entityId,
    formKey,
  ]);

  const onSubmit = useCallback(
    async (formData: FormValues) => {
      if (!workorder || !entity || !schema) return;
      // Replace # with . in keys to turn it back into a proper changeset
      const data = Object.keys(formData).reduce((acc, key) => {
        const id = fromFormName(key);
        const value = formData[key];
        if (value !== null) {
          acc[id] = value;
        }
        return acc;
      }, {} as EntityProps);

      const request: WorkOrderRequest = {
        ...workorder,
        entity_changesets: {
          ...workorder?.entity_changesets,
          [entity.id]: {
            entity_id: entity.id,
            prev_changeset_id: entity.changeset_head,
            properties: filterProperties(data, schema),
          },
        },
      };

      await update.mutateAsync(request);

      deleteStorageProperty(entityProperty.data['meta.id'] as string);

      navigate({
        to: '/workorders/$workorderId',
        params: { workorderId: workorder.id },
      });
    },
    [
      entity,
      navigate,
      schema,
      update,
      workorder,
      deleteStorageProperty,
      entityProperty,
    ],
  );

  return (
    <form key={formKey} className="flex w-full flex-col items-center">
      <div className="flex w-full flex-col overflow-auto md:max-w-screen-lg">
        <EntityGalleryMap
          entityId={entity.id}
          properties={entity.properties}
          schema={schema}
          control={control}
        />
        <div className="flex w-full justify-between py-4">
          <CheckBox
            id="edit-attributes"
            label="Endast redigeringsfält"
            checked={showEditAttributes}
            onClick={() => toggleAttributes()}
          />
          {lastSaved && (
            <div className="flex flex-row gap-2 text-sm text-gray-500">
              <span>
                <FormattedMessage defaultMessage="Autosparades: " />
              </span>
              <span>{lastSaved}</span>
            </div>
          )}
        </div>
        <EntityPropertyList
          edit
          entityId={entity.id}
          properties={entity.properties}
          schema={schema}
          control={control}
          setValue={setValue}
        />
      </div>
      <div className="sticky bottom-0 flex w-full justify-center gap-4 border-t border-t-background-200 bg-background-50 py-4">
        <Button
          startIcon={<ArrowLeftIcon className="size-5" />}
          onClick={() => {
            navigate({
              to: '/workorders/$workorderId',
              params: { workorderId },
            });
          }}
        >
          <FormattedMessage defaultMessage="Tillbaka" />
        </Button>
        <Button
          variant="filled"
          onClick={handleSubmit(onSubmit)}
          disabled={!formState.isValid || formState.isSubmitting}
        >
          <FormattedMessage defaultMessage="Färdigställ" />
        </Button>
      </div>
    </form>
  );
}
