import { createFileRoute } from '@tanstack/react-router';
import { WorkOrder, useWorkOrder } from '../../../../../hooks/useWorkOrders';
import { ErrorPage } from '../../../../../components/ErrorPage';
import { FormattedMessage } from 'react-intl';
import { Spinner } from '../../../../../components/Spinner';
import { useWorkOrderEvent } from '../../../../../hooks/useWorkOrderEvents';
import { UserName } from '../../../../../components/UserName';
import { ApiCommentEvent } from '@allbin/mobilix-api-client';
import { useConfig } from '../../../../../hooks/useConfig';

export const Route = createFileRoute(
  '/_layout/workorders/$workorderId/events/$eventId',
)({
  component: WorkOrderEventDetails,
});

function WorkOrderEventDetails() {
  const { workorderId, eventId } = Route.useParams();

  const workorder = useWorkOrder(workorderId);
  const event = useWorkOrderEvent(workorderId, eventId);

  if (workorder.isLoading || event.isLoading) {
    return (
      <div className="mt-16 flex w-full justify-center">
        <Spinner />
      </div>
    );
  }

  if (workorder.isError && !workorder.error.message.endsWith('404')) {
    console.error(workorder.error);
    return <ErrorPage error={workorder.error.message} />;
  }

  if (event.isError && !event.error.message.endsWith('404')) {
    console.error(event.error);
    return <ErrorPage error={event.error.message} />;
  }

  if (!workorder.data || !event.data) {
    return (
      <ErrorPage
        title={<FormattedMessage defaultMessage="Kunde inte hitta händelse" />}
        error={`Arbetsorder #${workorderId.substring(0, 6)} har ingen händelse med begärt id`}
      />
    );
  }

  switch (event.data.type) {
    case 'comment':
      return (
        <CommentEvent
          workorder={workorder.data}
          event={event.data}
          by={event.data.meta.created_by}
        />
      );
    default:
      return (
        <ErrorPage error={`Händelsetypen ${event.data.type} stöds inte`} />
      );
  }
}

interface CommentEventProps {
  workorder: WorkOrder;
  event: ApiCommentEvent;
  by: string;
}
function CommentEvent({ workorder, event, by }: CommentEventProps) {
  const { baseUrl } = useConfig();
  return (
    <>
      <header className="sticky top-0 bg-background-50">
        <h2 className="my-8">
          {workorder.title}
          <span className="ml-2 text-text-700">#{workorder.title_suffix}</span>
        </h2>
        <h3 className="flex">
          <span className="mr-2">
            <UserName id={by} />
          </span>
          <FormattedMessage defaultMessage="kommenterade" />
        </h3>
      </header>
      <div className="mt-8 flex flex-col gap-4">
        {event.data.text && (
          <div className="flex flex-col">
            <h6>Kommentar</h6>
            <p>{event.data.text}</p>
          </div>
        )}
        {event.data.attachments && event.data.attachments.length > 0 && (
          <div className="flex flex-col">
            <h6>Bild</h6>
            {event.data.attachments.map((image) => (
              <div key={image.attachment}>
                <img
                  src={`${baseUrl}/attachments/${image.attachment}`}
                  alt="Bild"
                  className="object-contain"
                />
                {image.name}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
