import { DateTime } from 'luxon';
import { ApiCheckIn, ApiMetadata } from '@allbin/mobilix-api-client/lib/api';
import { Metadata } from '../types/metadata';
import { ListAndLookup } from './listandlookup';
import { CheckIn } from '../types/entities';

export type ReviverFn<ApiItem, Item> = (item: ApiItem) => Item;

export const reviveMetadata: ReviverFn<ApiMetadata, Metadata> = (metadata) => ({
  created_at: DateTime.fromISO(metadata.created_at),
  created_by: metadata.created_by,
  updated_at: DateTime.fromISO(metadata.updated_at),
  deleted_at: metadata.deleted_at
    ? DateTime.fromISO(metadata.deleted_at)
    : undefined,
  deleted_by: metadata.deleted_by,
});

export const reviveCheckIn: ReviverFn<ApiCheckIn, CheckIn> = (apiCheckIn) => ({
  ...apiCheckIn,
  timestamp: DateTime.fromISO(apiCheckIn.timestamp),
});

export const toListAndLookup = <TApi, T extends { id: string }>(
  items: TApi[] | undefined,
  reviverFn: ReviverFn<TApi, T>,
): ListAndLookup<T> =>
  items
    ? items.reduce<ListAndLookup<T>>(
        (acc, item) => {
          const revived = reviverFn(item);
          return {
            list: [...acc.list, revived],
            lookup: { ...acc.lookup, [revived.id]: revived },
          };
        },
        { list: [] as T[], lookup: {} as Record<T['id'], T> },
      )
    : { list: [] as T[], lookup: {} as Record<T['id'], T> };

// interface IdAndMeta {
//   id: string;
//   meta: Metadata;
// }
//
// interface ApiIdAndMeta {
//   id: string;
//   meta: ApiMetadata;
// }
// interface LALFuncs<TApi extends ApiIdAndMeta, TData extends IdAndMeta> {
//   /** Will overwrite the complete state if any changes are present. */
//   set: (items: TApi[]) => ListAndLookup<TData>;
//   /** Merges new data with existing state, if any changes are present. */
//   merge: (items: TApi[]) => ListAndLookup<TData>;
//   /** Clears matching items from state, if any of those items are present. */
//   clear: (items: TApi[] | string[]) => ListAndLookup<TData>;
// }

// interface ListAndLookupState<TApi extends ApiIdAndMeta, TData extends IdAndMeta>
//   extends ListAndLookup<TData>,
//     LALFuncs<TApi, TData> {
//   touched: boolean;
// }

// // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
// export const createListAndLookupStore = <
//   TApi extends ApiIdAndMeta,
//   TData extends IdAndMeta,
// >(
//   reviver: ReviverFn<TApi, TData>,
//   storeName: string,
// ) =>
//   // ): UseBoundStore<ListAndLookupState<TApi, TData>> =>
//   create<ListAndLookupState<TApi, TData>>()(
//     devtools(
//       (set, get) => ({
//         touched: false,
//         list: [] as TData[],
//         lookup: {} as Record<TData['id'], TData>,
//         merge: (items) => {
//           const state = get();
//           const list: ListAndLookup<TData>['list'] = [...state.list];
//           const lookup: ListAndLookup<TData>['lookup'] = { ...state.lookup };
//           let anyChanges = false;
//
//           items.forEach((item) => {
//             if (
//               !lookup[item.id] ||
//               (lookup[item.id] &&
//                 lookup[item.id].meta.updated_at.toISO() !==
//                   item.meta.updated_at)
//             ) {
//               //Item has changed or is new.
//               anyChanges = true;
//               const revived = reviver(item);
//               lookup[item.id] = revived;
//               const idx = list.findIndex((x) => x.id === item.id);
//               if (idx > -1) {
//                 list.splice(idx, 1, revived);
//               } else {
//                 list.push(revived);
//               }
//             }
//           });
//
//           if (!anyChanges) {
//             return state;
//           }
//           const newState = {
//             list,
//             lookup,
//             touched: true,
//           };
//           set(newState);
//           return newState;
//         },
//         clear: (itemsOrIds) => {
//           const state = get();
//           const list: ListAndLookup<TData>['list'] = [...state.list];
//           const lookup: ListAndLookup<TData>['lookup'] = { ...state.lookup };
//           let anyChanges = false;
//           const ids: string[] = itemsOrIds.map<string>((item) =>
//             typeof item === 'string' ? item : item.id,
//           );
//
//           ids.forEach((id) => {
//             if (lookup[id]) {
//               //Item is found and should be deleted.
//               anyChanges = true;
//               delete lookup[id];
//               const idx = list.findIndex((x) => x.id === id);
//               if (idx > -1) {
//                 list.splice(idx, 1);
//               }
//             }
//           });
//
//           if (!anyChanges) {
//             return state;
//           }
//           const newState = {
//             list,
//             lookup,
//             touched: true,
//           };
//           set(newState);
//           return newState;
//         },
//         set: (items) => {
//           const state = get();
//           const revivedItems = items.map(reviver);
//           const hasChanges =
//             state.list.length !== revivedItems.length ||
//             revivedItems.some((item) => {
//               const changed =
//                 !state.lookup[item.id] ||
//                 !state.lookup[item.id].meta.updated_at.equals(
//                   item.meta.updated_at,
//                 );
//               return changed;
//             });
//
//           if (!hasChanges) {
//             return state;
//           }
//
//           const results = {
//             touched: true,
//             list: revivedItems,
//             lookup: revivedItems.reduce<Record<TData['id'], TData>>(
//               (acc, item) => {
//                 acc[item.id as TData['id']] = item;
//                 return acc;
//               },
//               {} as Record<TData['id'], TData>,
//             ),
//           };
//           set(results);
//           return results;
//         },
//       }),
//       { name: storeName, enabled: process.env.NODE_ENV !== 'test' },
//     ),
//   );
