import { DateTime } from 'luxon';
import { useCallback, useMemo, useState } from 'react';
import { UseControllerProps, useController } from 'react-hook-form';
import { Popover, PopoverContent, PopoverTrigger } from '../../popover';
import Button from '@/components/core/Button';
import { IconCalendar } from '@allbin/icons';
import { Calendar } from '../../calendar';
import { FormValues } from '../EntityPropertyList';
import { FormattedMessage } from 'react-intl';

export function DateInput(props: UseControllerProps<FormValues>) {
  const { field } = useController(props);

  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const value = useMemo(() => {
    if (!field.value) return undefined;
    return DateTime.fromISO(field.value as string).toJSDate();
  }, [field.value]);

  const onChange = useCallback(
    (date: Date | undefined) => {
      field.onChange(date?.toISOString() || null);
      setDatePickerOpen(false);
    },
    [field],
  );

  return (
    <Popover open={datePickerOpen} onOpenChange={setDatePickerOpen}>
      <PopoverTrigger asChild>
        <Button id="end_date" className="w-full">
          <IconCalendar className="mr-2 size-4" />
          {value
            ? new Intl.DateTimeFormat('sv-SE', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(value)
            : 'Välj datum'}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          required={false}
          selected={value ?? undefined}
          onSelect={onChange}
          initialFocus
        />
        <div className="flex items-center justify-end pb-2 pr-2">
          <Button onClick={() => onChange(undefined)}>
            <FormattedMessage defaultMessage="Inget datum" />
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
}
