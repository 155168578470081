import {
  IconClockRotateRight,
  IconSend,
  IconThumbsUp,
  IconUserXmark,
  IconXmark,
} from '@allbin/icons';
import { WorkOrder, useWorkOrderUpdate } from '../hooks/useWorkOrders';
import Button from './core/Button';
import { FormattedMessage } from 'react-intl';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import { useNavigate } from '@tanstack/react-router';
import { toast } from '@/hooks/useToast';

interface ActionButtonsProps {
  workorder: WorkOrder;
}

export function WorkOrderActionButtons({ workorder }: ActionButtonsProps) {
  const { user } = useAuth0();
  const updateWorkOrder = useWorkOrderUpdate();
  const navigate = useNavigate();

  const toastWorkOrder = useCallback(
    (state: string) => {
      if (state === 'accepted') {
        toast.success({
          title: 'Arbetsordern övertagen',
          description: 'Arbetsordern är accepterad av ' + user?.name,
        });
      }
    },
    [user?.name],
  );

  const acceptWorkOrder = useCallback(() => {
    updateWorkOrder.mutate(
      {
        ...workorder,
        assignee: user?.sub,
        state: 'accepted',
      },
      {
        onSuccess: () => {
          toastWorkOrder(workorder.state);
        },
      },
    );
  }, [updateWorkOrder, user?.sub, workorder, toastWorkOrder]);

  const returnWorkOrder = useCallback(() => {
    updateWorkOrder.mutate({
      ...workorder,
      assignee: undefined,
      state: 'created',
    });
  }, [updateWorkOrder, workorder]);

  const blockWorkOrder = useCallback(async () => {
    await updateWorkOrder.mutateAsync({
      ...workorder,
      assignee: undefined,
      state: 'blocked',
    });

    navigate({
      to: '/workorders/$workorderId/comment',
      params: {
        workorderId: workorder.id,
      },
    });
  }, [navigate, updateWorkOrder, workorder]);

  const submitWorkOrder = useCallback(() => {
    updateWorkOrder.mutate({
      ...workorder,
      state: 'completed',
    });
  }, [updateWorkOrder, workorder]);

  return (
    <div className="flex w-full flex-wrap items-center justify-center gap-2 py-4">
      {canAccept(user?.sub, workorder) && (
        <Button
          variant="filled"
          onClick={acceptWorkOrder}
          disabled={updateWorkOrder.isPending}
          startIcon={<IconThumbsUp />}
        >
          {workorder.assignee ? (
            <FormattedMessage defaultMessage="Ta Över Ordern" />
          ) : (
            <FormattedMessage defaultMessage="Acceptera Ordern" />
          )}
        </Button>
      )}
      {canReturn(user?.sub, workorder) && (
        <Button
          startIcon={<IconUserXmark />}
          variant="filled"
          onClick={returnWorkOrder}
          disabled={updateWorkOrder.isPending}
        >
          <FormattedMessage defaultMessage="Återlämna Ordern" />
        </Button>
      )}
      {canBlock(user?.sub, workorder) && (
        <Button
          startIcon={<IconXmark />}
          variant="filled"
          onClick={blockWorkOrder}
          disabled={updateWorkOrder.isPending}
        >
          <FormattedMessage defaultMessage="Blockera" />
        </Button>
      )}
      <Button
        startIcon={<IconClockRotateRight />}
        variant="filled"
        onClick={() =>
          navigate({
            to: '/workorders/$workorderId/events',
            params: {
              workorderId: workorder.id,
            },
          })
        }
        disabled={updateWorkOrder.isPending}
      >
        <FormattedMessage defaultMessage="Händelser" />
      </Button>
      {canSubmit(user?.sub, workorder) && (
        <Button
          startIcon={<IconSend />}
          variant="filled"
          onClick={submitWorkOrder}
          disabled={updateWorkOrder.isPending}
        >
          <FormattedMessage defaultMessage="Skicka In" />
        </Button>
      )}
    </div>
  );
}

function canAccept(user?: string, workorder?: WorkOrder) {
  if (
    !user ||
    !workorder ||
    (workorder.state !== 'completed' && user === workorder.assignee)
  )
    return false;

  return (
    workorder.state === 'accepted' ||
    workorder.state === 'created' ||
    workorder.state === 'blocked' ||
    workorder.state === 'rejected' ||
    workorder.state === 'completed'
  );
}

function canReturn(user?: string, workorder?: WorkOrder) {
  if (!user || !workorder || user !== workorder.assignee) return false;

  return workorder.state === 'accepted';
}

function canBlock(user?: string, workorder?: WorkOrder) {
  if (!user || !workorder || user !== workorder.assignee) return false;

  return workorder.state === 'accepted';
}

function canSubmit(user?: string, workorder?: WorkOrder) {
  if (!user || !workorder || user !== workorder.assignee) return false;

  return workorder.state === 'accepted';
}
