import {
  createFileRoute,
  useNavigate,
  useRouter,
} from '@tanstack/react-router';
import { useEntity } from '../../../../hooks/useEntities';
import { useEntitySchema } from '../../../../hooks/useEntitySchema';
import { Spinner } from '../../../../components/Spinner';
import { FormEvent, useCallback, useMemo, useRef, useState } from 'react';
import { ErrorPage } from '../../../../components/ErrorPage';
import { z } from 'zod';
import { PageHeader } from '../../../../components/PageHeader';
import { FormattedMessage, useIntl } from 'react-intl';
import { useCreateAttachment } from '../../../../hooks/useAttachments';
import Button from '@/components/core/Button';
import { useCreateCheckIn } from '../../../../hooks/useCheckIn';
import { useCreateErrorReport } from '../../../../hooks/useErrorReport';
import { IconArrowLeft } from '@allbin/icons';
import validator from 'validator';
import { toast } from '@/hooks/useToast';
import Input from '@/components/core/Input';

const errorReportSearchSchema = z.object({
  propertyId: z.string(),
});

export const Route = createFileRoute(
  '/_layout/entities/$entityId/error_report',
)({
  component: ErrorReport,
  validateSearch: (search) => errorReportSearchSchema.parse(search),
});

function ErrorReport() {
  const { history } = useRouter();

  const { entityId } = Route.useParams();
  const { propertyId } = Route.useSearch();

  const schema = useEntitySchema();
  const entity = useEntity(entityId);
  const [reportSuccess, setReportSuccess] = useState(false);

  const createAttachment = useCreateAttachment();
  const createCheckIn = useCreateCheckIn();
  const createErrorReport = useCreateErrorReport();

  const isLoading = useMemo(
    () => entity.isLoading || createAttachment.isPending,
    [entity.isLoading, createAttachment.isPending],
  );

  const [saving, setSaving] = useState(false);
  const intl = useIntl();
  const [comment, setComment] = useState('');
  const [image, setImage] = useState<File | null>(null);

  const preview = useMemo(() => {
    if (!image) return null;
    return URL.createObjectURL(image);
  }, [image]);

  const canSave = useMemo(
    () => comment.trim().length > 0 || !!image,
    [comment, image],
  );

  const imageUploadInputRef = useRef<HTMLInputElement>(null);

  const handleImageSelect = useCallback(() => {
    imageUploadInputRef.current?.click();
  }, []);

  const handleFileUpload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const fileUploaded = e.target.files?.[0];
      if (!fileUploaded) {
        return;
      }
      setImage(fileUploaded);
    },
    [],
  );

  const propertyDefinition = useMemo(
    () => schema.data?.definition.properties.find((p) => p.key === propertyId),
    [propertyId, schema.data?.definition.properties],
  );

  const supportUrls = useMemo(
    () => schema.data?.extras?.[propertyId]?.support_urls || {},
    [schema.data?.extras, propertyId],
  );

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (!canSave) return;

      setSaving(true);
      try {
        // create checkin
        await createCheckIn.mutateAsync(entityId);

        // create error report
        await createErrorReport.mutateAsync({
          destination: supportUrls['default'],
          entity_id: entityId,
          message: comment || undefined,
          property_key: propertyId,
        });

        // display support url
        setReportSuccess(true);
      } catch (e) {
        console.error('Failed to send error report', e);
        toast.error({
          title: intl.formatMessage({
            defaultMessage: 'Kunde inte skicka felrapport',
          }),
          description: (e as Error).message,
        });
      } finally {
        setSaving(false);
      }
    },
    [
      canSave,
      comment,
      createCheckIn,
      createErrorReport,
      entityId,
      intl,
      propertyId,
      supportUrls,
    ],
  );

  if (schema.isLoading || entity.isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  if (schema.error || entity.error || !propertyDefinition) {
    return <ErrorPage error={schema.error?.message || entity.error?.message} />;
  }

  if (reportSuccess) {
    return (
      <ErrorReportSuccessful
        entityId={entityId}
        propertyId={propertyId}
        supportUrl={supportUrls['default']}
      />
    );
  }

  return (
    <>
      <PageHeader
        title={
          <FormattedMessage
            defaultMessage="Felanmäl {entity}"
            values={{
              entity: entity.data?.full_name,
            }}
          />
        }
        subtitle={
          <FormattedMessage
            defaultMessage='Attribut "{name}"'
            values={{ name: propertyDefinition.name }}
          />
        }
      />
      <form
        className="flex flex-col items-center gap-4"
        onSubmit={(e) => handleSubmit(e)}
      >
        <div className="flex w-full grow flex-col items-start gap-4 md:flex-row md:gap-16">
          <fieldset className="mt-4 flex w-full flex-1 flex-col">
            <Input
              id="comment"
              label={<FormattedMessage defaultMessage="Kommentar" />}
              placeholder="Skriv en kommentar"
              disabled={isLoading}
              value={comment}
              type="multiline"
              rows={4}
              onChange={(e) => setComment(e.currentTarget.value)}
            />
          </fieldset>
          <fieldset className="hidden w-full flex-1 flex-col gap-2">
            <label
              htmlFor="image"
              className="mb-1 block text-sm font-medium leading-6 text-primary-900"
            >
              <FormattedMessage defaultMessage="Bild" />
            </label>
            <input
              id="image"
              type="file"
              className="hidden"
              onChange={handleFileUpload}
              ref={imageUploadInputRef}
              accept="image/*"
            />
            {preview ? (
              <>
                <img
                  src={preview}
                  alt="Preview"
                  className="max-h-64 object-contain"
                />
                <Button onClick={() => setImage(null)} disabled={isLoading}>
                  <FormattedMessage defaultMessage="Ta bort bild" />
                </Button>
              </>
            ) : (
              <Button
                variant="filled"
                onClick={handleImageSelect}
                disabled={isLoading}
              >
                <FormattedMessage defaultMessage="Lägg till bild" />
              </Button>
            )}
          </fieldset>
        </div>
        <div className="grow" />
        <footer className="flex justify-end gap-4 bg-background-50 py-4">
          <Button onClick={() => history.back()} disabled={isLoading || saving}>
            <FormattedMessage defaultMessage="Avbryt" />
          </Button>
          <Button
            type="submit"
            variant="filled"
            disabled={!canSave || isLoading || saving}
          >
            <FormattedMessage defaultMessage="Skicka in" />
          </Button>
        </footer>
      </form>
    </>
  );
}

interface ErrorReportSuccessfulProps {
  entityId: string;
  propertyId: string;
  supportUrl: string;
}

function ErrorReportSuccessful({
  entityId,
  propertyId,
  supportUrl,
}: ErrorReportSuccessfulProps) {
  const navigate = useNavigate();

  const url = useMemo(() => {
    if (validator.isEmail(supportUrl)) {
      return `mailto:${supportUrl}`;
    }

    if (validator.isURL(supportUrl)) {
      if (!supportUrl.startsWith('http')) {
        return `https://${supportUrl}`;
      }
      return supportUrl;
    }

    return null;
  }, [supportUrl]);

  return (
    <>
      <PageHeader
        title={<FormattedMessage defaultMessage="Felrapporten har skickats" />}
      />
      <div className="flex flex-col gap-4">
        {url && (
          <>
            <p>
              <FormattedMessage defaultMessage="Vänligen kontakta supporten via länk" />
            </p>
            <a
              href={url}
              target="_blank"
              className="font-medium text-primary-600 hover:text-primary-500 active:text-primary-700"
            >
              {supportUrl}
            </a>
          </>
        )}
        <div className="flex items-center justify-center">
          <Button
            onClick={() =>
              navigate({
                to: '/entities/$entityId',
                params: { entityId },
                hash: propertyId,
              })
            }
          >
            <IconArrowLeft className="size-4" />
            <FormattedMessage defaultMessage="Tillbaka" />
          </Button>
        </div>
      </div>
    </>
  );
}
