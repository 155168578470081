import { useTag } from '../hooks/useTags';

interface TagNameProps {
  id: string;
}

export function TagName({ id }: TagNameProps) {
  const tag = useTag(id);

  return <>{tag.data?.name}</>;
}
