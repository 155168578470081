import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { WorkOrder, useWorkOrders } from '@/hooks/useWorkOrders';
import { FormattedMessage } from 'react-intl';
import { Tabs, TabsList, TabsTrigger } from '@/components/tabs';
import { useCallback, useMemo } from 'react';
import { ApiWorkOrderState } from '@allbin/mobilix-api-client';
import { WorkOrderList } from '@/components/WorkOrderList';
import { z } from 'zod';
import { StateName } from '@/components/StateName';
import { PageHeader } from '@/components/PageHeader';
import IconButton from '@/components/core/IconButton';
import { IconList, IconMap } from '@allbin/icons';
import { Spinner } from '@/components/Spinner';
import { NavBar } from '@/components/NavBar';
import { EntityMap } from '@/components/EntityMap';

const states = [
  'created',
  'blocked',
  'accepted',
  'completed',
  'rejected',
  'approved',
  'cancelled',
] as const;

const workorderSearchSchema = z.object({
  state: z.enum(states).optional(),
  mode: z.enum(['list', 'map']).default('list').optional(),
});

export const Route = createFileRoute('/workorders')({
  component: WorkOrders,
  validateSearch: (search) => workorderSearchSchema.parse(search),
});

function WorkOrders() {
  const navigate = useNavigate();
  const { state, mode } = Route.useSearch();
  const workorders = useWorkOrders();

  const tabs = useMemo(
    () =>
      (workorders.data || []).reduce<Record<ApiWorkOrderState, WorkOrder[]>>(
        (acc, workorder) => {
          acc[workorder.state].push(workorder);
          return acc;
        },
        {
          created: [],
          blocked: [],
          accepted: [],
          completed: [],
          rejected: [],
          approved: [],
          cancelled: [],
        },
      ),
    [workorders],
  );

  const handleTabChange = useCallback(
    (value: string) => {
      navigate({ search: { state: value, mode } });
    },
    [mode, navigate],
  );

  if (workorders.isLoading) {
    return (
      <div className="flex size-full h-full flex-col items-center">
        <main className="flex max-h-full w-full max-w-full grow flex-col overflow-auto ">
          <div className="px-2 sm:px-8 md:px-16">
            <PageHeader
              title={<FormattedMessage defaultMessage="Arbetsordrar" />}
            />
          </div>
          <div className="flex size-full flex-col items-center justify-center">
            <Spinner />
          </div>
        </main>
      </div>
    );
  }

  return (
    <div className="flex size-full h-full flex-col items-center">
      <main className="flex max-h-full w-full max-w-full flex-1 flex-col overflow-auto ">
        <div className="px-2 sm:px-8 md:px-16">
          <Tabs
            defaultValue="accepted"
            value={state}
            onValueChange={handleTabChange}
          >
            <PageHeader
              title={<FormattedMessage defaultMessage="Arbetsordrar" />}
              action={
                <IconButton
                  round
                  icon={mode === 'map' ? <IconList /> : <IconMap />}
                  onClick={() =>
                    navigate({
                      search: {
                        mode: mode === 'map' ? 'list' : 'map',
                        state,
                      },
                    })
                  }
                />
              }
              footer={
                <TabsList className="mb-2 w-full">
                  {states.map((state) => (
                    <TabsTrigger key={state} value={state}>
                      <StateName state={state} />
                      <span className="ml-1 hidden md:block">
                        ({tabs[state].length})
                      </span>
                    </TabsTrigger>
                  ))}
                </TabsList>
              }
            />
          </Tabs>
        </div>
        <div className="flex size-full items-stretch justify-stretch">
          <WorkOrderContent workorders={tabs[state || 'accepted']} />
        </div>
      </main>
      <NavBar />
    </div>
  );
}

interface WorkOrderContentProps {
  workorders: WorkOrder[];
}

function WorkOrderContent({ workorders }: WorkOrderContentProps) {
  const { mode } = Route.useSearch();

  switch (mode) {
    case 'map':
      return <EntityMap workorders={workorders} />;
    default:
      return (
        <div className="w-full px-2 sm:px-8 md:px-16">
          <WorkOrderList workorders={workorders} />
        </div>
      );
  }
}
