import { MobilixApiClient } from '@allbin/mobilix-api-client';
import { createFileRoute } from '@tanstack/react-router';
import { useMemo } from 'react';
import { useConfig } from '../hooks/useConfig';
import { useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '@/components/core/Button';
import { FormattedMessage } from 'react-intl';
import MobilixLogo from '../components/Logo';
import { Spinner } from '@/components/Spinner';

export const Route = createFileRoute('/login')({
  component: Login,
});

function Login() {
  const { baseUrl } = useConfig();
  const apiClient = useMemo(() => MobilixApiClient({ baseUrl }), [baseUrl]);
  const tenants = useQuery({
    queryKey: ['tenants'],
    queryFn: () => apiClient.tenants.list(),
  });
  const { loginWithRedirect, isLoading } = useAuth0();

  if (tenants.isLoading || isLoading) {
    return (
      <div className="flex flex-col items-center gap-8 p-8 sm:gap-16 sm:p-16">
        <MobilixLogo />
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center gap-8 p-8 sm:gap-16 sm:p-16">
      <MobilixLogo />
      <div className="flex flex-col gap-4">
        <h2>
          <FormattedMessage defaultMessage="Välj organisation" />
        </h2>
        <p>
          <FormattedMessage defaultMessage="Välj den organisationen du vill logga in hos. Du kan enbart logga in med organisationer där du redan har ett konto." />
        </p>
        <p>
          <FormattedMessage defaultMessage="Organisationsvalet sparas för framtida inloggningar. Om du vill byta organisation, klicka “byt organisation” i dina kontoinställningar." />
        </p>
      </div>
      <ul className="flex flex-col gap-4">
        {tenants.data?.map((tenant) => (
          <Button
            key={tenant.id}
            className="w-full px-16"
            variant="filled"
            onClick={() =>
              loginWithRedirect({
                authorizationParams: {
                  organization: tenant.organization_id,
                  redirect_uri: window.location.origin + '/callback',
                },
              })
            }
          >
            {tenant.name}
          </Button>
        ))}
      </ul>
    </div>
  );
}
