import { useCheckInPlans } from './useCheckIn';
import { useMemo } from 'react';
import { useEntities } from './useEntities';
import { getPeriodStatus } from '@allbin/mobilix-utils';

export function useOngoingCheckinEntities() {
  const checkins = useCheckInPlans();
  const entities = useEntities();

  const getCheckinsEntities = useMemo(() => {
    if (!entities.data || !checkins || !checkins.data) {
      return [];
    }

    return checkins.data.map((checkin) => {
      const checkinEntities = entities.data.filter((entity) =>
        checkin.entities.includes(entity.id),
      );

      const remaining = checkinEntities.filter((entity) => {
        const status = getPeriodStatus(checkin.activePeriod, [
          ...(entity.checkins?.contractor || []),
          ...(entity.checkins?.admin || []),
        ]);
        return status.executed === 'missed';
      });

      return {
        checkin: checkin,
        remainingEntities: remaining,
      };
    });
  }, [checkins, entities.data]);

  return getCheckinsEntities;
}
