import {
  ApiEntitySchemaExtra,
  ApiEntitySchemaProp,
} from '@allbin/mobilix-api-client';
import { EntityPropValue } from '../../types/entities';
import { FormattedMessage } from 'react-intl';
import {
  formatNumber,
  locationPropertyToString,
} from '../../utils/formatting_helpers';
import { Fragment, useMemo } from 'react';
import { useConfig } from '../../hooks/useConfig';
import { Link, useParams } from '@tanstack/react-router';
import { defaultProfile, useUserProfile } from '../../hooks/useProfile';
import { DateTime } from 'luxon';

export interface EntityProperty<T = EntityPropValue>
  extends ApiEntitySchemaProp {
  entityId: string;
  value: T | null;
  extras?: ApiEntitySchemaExtra;
}

interface EntityPropertyParams {
  property: EntityProperty;
}

export function EntityPropertyValue({ property }: EntityPropertyParams) {
  if (property.value === undefined || property.value === null) {
    return <MissingProperty />;
  }

  switch (property.type) {
    case 'string':
      return (
        <StringProperty
          value={property.value as string}
          extras={property.extras}
        />
      );
    case 'number':
      return (
        <NumberProperty
          value={property.value as number}
          extras={property.extras}
        />
      );
    case 'boolean':
      return (
        <BooleanProperty
          value={property.value as boolean}
          extras={property.extras}
        />
      );
    // TODO: Not implemented in Mobilix UI schema editor yet
    // case 'array:number':
    case 'array:string':
      return <StringArrayProperty value={property.value as string[]} />;
    case 'date':
      return (
        <DateProperty
          value={property.value as string}
          extras={property.extras}
        />
      );
    case 'enum':
      return (
        <StringProperty
          value={property.value as string}
          extras={property.extras}
        />
      );
    case 'photo':
      return <PhotoProperty value={property.value as string} />;
    case 'location':
      return <LocationProperty value={property.value as number[]} />;
    default:
      console.info(`Unsupported schema type: ${property.type}`);
      return <>{JSON.stringify(property.value)}</>;
  }
}

interface Props<T> {
  value: T | null;
  extras?: ApiEntitySchemaExtra;
}

function MissingProperty() {
  return (
    <span className="italic text-text-500">
      <FormattedMessage defaultMessage="Saknas" />
    </span>
  );
}

function StringProperty({ value }: Props<string>) {
  return <>{value}</>;
}

function NumberProperty({ value, extras }: Props<number>) {
  if (value === null) {
    return <MissingProperty />;
  }
  return <>{formatNumber(value, extras?.number_options)}</>;
}

function BooleanProperty({ value }: Props<boolean>) {
  if (value) {
    return <FormattedMessage defaultMessage="Ja" />;
  }

  return <FormattedMessage defaultMessage="Nej" />;
}

function StringArrayProperty({ value }: Props<string[]>) {
  return (
    <>
      {value?.map((item, index) => (
        <Fragment key={index}>
          {index > 0 && ', '}
          {item}
        </Fragment>
      ))}
    </>
  );
}

function DateProperty({ value }: Props<string>) {
  if (!value) {
    return null;
  }

  return <>{DateTime.fromISO(value).toFormat('yyyy-MM-dd HH:mm')}</>;
}

function PhotoProperty({ value }: Props<string>) {
  const { cdnUrl } = useConfig();
  const { entityId } = useParams({ from: '/_layout/entities/$entityId/' });

  if (!value) {
    return <MissingProperty />;
  }

  return (
    <Link
      to="/entities/$entityId/images"
      params={{ entityId }}
      search={{ image: value }}
    >
      <img src={`${cdnUrl}/${value}/sm`} />
    </Link>
  );
}

function LocationProperty({ value }: Props<number[]>) {
  const profile = useUserProfile();
  const projection = useMemo(
    () => profile.data?.projection ?? defaultProfile.projection,
    [profile.data?.projection],
  );

  const location = useMemo(() => {
    if (!value) {
      return null;
    }

    if (
      value.length !== 2 ||
      value.some((x) => typeof x !== 'number' || Number.isNaN(x))
    ) {
      return value.toString();
    }

    return locationPropertyToString([value[0], value[1]], projection);
  }, [value, projection]);

  if (profile.isLoading) {
    return <div className="h-6 w-32 animate-pulse rounded bg-primary-200" />;
  }

  if (!location) {
    return <MissingProperty />;
  }

  return <>{location}</>;
}
