import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { FormattedMessage } from 'react-intl';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '@/components/core/Button';
import { IconBusStop, IconEye, IconGoogleDocs } from '@allbin/icons';
import { WorkOrderList } from '../../components/WorkOrderList';
import { useWorkOrders } from '../../hooks/useWorkOrders';
import { useMemo } from 'react';
import { PageHeader } from '../../components/PageHeader';

export const Route = createFileRoute('/_layout/')({
  component: Index,
});

function Index() {
  const { user } = useAuth0();
  const navigate = useNavigate();

  const workorders = useWorkOrders();
  const myWorkorders = useMemo(
    () =>
      workorders.data
        ?.filter((w) => w.assignee === user?.sub && w.state === 'accepted')
        .slice(0, 5),
    [workorders.data, user?.sub],
  );

  return (
    <>
      <PageHeader
        title={<FormattedMessage defaultMessage="Mobilix" />}
        subtitle={
          user?.name && (
            <FormattedMessage
              defaultMessage="Ordrar tilldelade {name}"
              values={{ name: user.name }}
            />
          )
        }
      />
      <div className="flex w-full grow flex-col gap-4">
        <WorkOrderList
          workorders={myWorkorders}
          isLoading={workorders.isLoading}
        />
      </div>
      <div className="flex w-full flex-row justify-center gap-4 pb-4">
        <Button
          onClick={() => navigate({ to: '/workorders' })}
          round
          size="lg"
          variant="filled"
        >
          <IconGoogleDocs className="size-5" />
          <FormattedMessage defaultMessage="Arbetsordrar" />
        </Button>
        <Button
          onClick={() =>
            navigate({ to: '/entities', search: { mode: 'list' } })
          }
          round
          size="lg"
          variant="filled"
          color="entity"
        >
          <IconBusStop className="size-5" />
          <FormattedMessage defaultMessage="Hållplatser" />
        </Button>
        <Button
          onClick={() => navigate({ to: '/checkins' })}
          round
          size="lg"
          variant="filled"
          color="checkin"
        >
          <IconEye className="size-5" />
          <FormattedMessage defaultMessage="Tillsyn" />
        </Button>
      </div>
    </>
  );
}
