import { useEntity } from '../hooks/useEntities';
import { cn } from '../utils/classnames';

export function EntityName({
  id,
  trailingComma,
}: {
  id: string;
  trailingComma: boolean;
}) {
  const entity = useEntity(id);
  if (entity.isLoading) {
    return (
      <span className="mr-2 inline-block h-3 w-24 animate-pulse rounded bg-gray-300"></span>
    );
  }
  return (
    <span className={cn('mr-2', trailingComma && "after:content-[',']")}>
      {entity.data?.full_name}
    </span>
  );
}
