import { useQuery } from '@tanstack/react-query';
import { useApiClient } from './useApiClient';
import { Metadata } from '../types/metadata';
import { ApiUserProfile } from '@allbin/mobilix-api-client';
import { reviveMetadata } from '../utils/revivers';
import { UserProfile } from '../types/user';

export const defaultProfile: UserProfile = {
  projection: 'EPSG:3021',
  language: 'sv-SE',
  meta: {} as Metadata,
  visible_columns: [],
};

const reviveUserProfile = (apiProfile: ApiUserProfile): UserProfile => {
  return {
    projection: defaultProfile.projection,
    language: defaultProfile.language,
    visible_columns: defaultProfile.visible_columns,
    ...apiProfile.profile,
    meta: reviveMetadata(apiProfile.meta),
  };
};

export const useUserProfile = () => {
  const client = useApiClient();

  return useQuery<UserProfile, Error>({
    queryKey: ['userProfiles', 'profile'],
    queryFn: () => client.userProfiles.get().then(reviveUserProfile),
  });
};
