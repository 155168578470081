import { forwardRef } from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogPortal,
  DialogTrigger,
  DialogClose,
} from './dialog';
import { IconHelpCircle } from '@allbin/icons';
import { Help } from './Help';
import Button from './core/Button';

export const HelpDialog = forwardRef(function HelpDialog() {
  const [helpOpen, setHelpOpen] = useState(false);

  return (
    <Dialog open={helpOpen} onOpenChange={setHelpOpen}>
      <DialogTrigger asChild>
        <Button variant="ghost" className="flex items-center gap-2 p-2 ">
          <IconHelpCircle className="size-5" />
          <FormattedMessage defaultMessage="Hjälp" />
        </Button>
      </DialogTrigger>
      <DialogPortal>
        <DialogContent className="h-full max-h-full max-w-full grid-rows-[min-content_1fr_min-content]">
          <DialogHeader>
            <h3>
              <FormattedMessage defaultMessage="Mobilix Hjälpmanual" />
            </h3>
          </DialogHeader>
          <Help />
          <DialogFooter>
            <Button
              onClick={() => {
                window.open('mailto:support@allbinary.se', '_blank');
              }}
            >
              <FormattedMessage defaultMessage="Kontakta supporten" />
            </Button>
            <DialogClose asChild>
              <Button variant="filled">
                <FormattedMessage defaultMessage="Stäng" />
              </Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
});
