import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useApiClient } from './useApiClient';
import { Entity } from '../types/entities';
import { ReviverFn, reviveMetadata } from '@/utils/revivers';
import { ApiCheckInPlan } from '@allbin/mobilix-api-client';
import { Metadata } from '@/types/metadata';
import {
  PeriodicityPeriod,
  getPeriodStatus,
  getPeriodicityPeriods,
} from '@allbin/mobilix-utils';
import { useEntities } from './useEntities';

export type ExecutionStatus =
  | 'on_time'
  | 'late'
  | 'missed'
  | 'unknown'
  | 'loading';

export interface CheckInPlan
  extends Omit<ApiCheckInPlan, 'meta' | 'tenant_id'> {
  meta: Metadata;
  previousPeriod: PeriodicityPeriod;
  activePeriod: PeriodicityPeriod;
  executionStatus: ExecutionStatus;
}

export const reviveCheckInPlan: ReviverFn<ApiCheckInPlan, CheckInPlan> = (
  checkInPlan,
) => {
  try {
    const { periods, activePeriodIndex } = getPeriodicityPeriods(
      checkInPlan.periodicity,
      checkInPlan.duration,
      1,
      0,
    );
    const previousPeriod = periods[activePeriodIndex - 1];
    const activePeriod = periods[activePeriodIndex];

    return {
      ...checkInPlan,
      meta: reviveMetadata(checkInPlan.meta),
      previousPeriod,
      activePeriod,
      executionStatus: 'unknown',
    };
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const useCheckInPlan = (id: string) => {
  const client = useApiClient();
  return useQuery({
    queryKey: ['check-in-plans', id],
    queryFn: async () => {
      return reviveCheckInPlan(await client.checkInPlans.get(id));
    },
  });
};

export const useCheckInPlans = () => {
  const client = useApiClient();
  const entities = useEntities();

  return useQuery({
    queryKey: ['check-in-plans'],
    queryFn: async () => {
      const list = await client.checkInPlans.list();
      const revived = list.map(reviveCheckInPlan);
      return revived.map((checkInPlan) => {
        const checkInPlanEntities =
          entities.data?.filter((e) => checkInPlan.entities.includes(e.id)) ||
          [];

        const executionStatus = checkInPlanEntities.reduce<ExecutionStatus>(
          (acc, entity) => {
            const status = getPeriodStatus(
              checkInPlan.activePeriod,
              entity.checkins?.contractor || [],
            ).executed;

            if (status === 'missed') {
              return 'missed';
            }

            if (status === 'late' && acc !== 'missed') {
              return 'late';
            }

            if (status === 'on_time' && acc !== 'missed' && acc !== 'late') {
              return 'on_time';
            }

            return acc;
          },
          'unknown',
        );

        return { ...checkInPlan, executionStatus };
      });
    },
    enabled: entities.isSuccess,
  });
};

export const useCreateCheckIn = () => {
  const client = useApiClient();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (entityId: Entity['id']) =>
      client.entities.createCheckIn(entityId),
    onSuccess: (_, entityId) => {
      void queryClient.invalidateQueries({ queryKey: ['entities'] });
      void queryClient.invalidateQueries({ queryKey: ['entity', entityId] });
    },
  });
};
