import { FormattedMessage } from 'react-intl';
import Button from './core/Button';
import { useRegisterSW } from 'virtual:pwa-register/react';

export function PWAUpdatePrompt() {
  const buildDate: string = '__DATE__';
  const reloadSW: string = '__RELOAD_SW__';

  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swUrl, r) {
      console.log('Service Worker at:', swUrl);
      if (reloadSW === 'true') {
        r &&
          setInterval(() => {
            r.update();
          }, 20000);
      } else {
        console.log('SW Registered:', r);
      }
      console.log('SW is up to date');
    },
    onRegisterError(e) {
      console.error('error registering SW', e);
    },
  });

  const refresh = async () => {
    await updateServiceWorker(true);
  };

  const close = () => {
    setNeedRefresh(false);
  };

  if (!needRefresh) {
    return null;
  }

  return (
    <div className="absolute right-4 top-4 z-50 flex flex-col gap-4 rounded border border-background-300 bg-background-100 p-2 shadow-xl">
      <p>
        <FormattedMessage defaultMessage="Det finns en ny version att hämta" />
      </p>
      <div className="flex items-center justify-center gap-2">
        <Button onClick={() => refresh()}>Uppdatera</Button>
        <Button onClick={() => close()}>Stäng</Button>
      </div>
      <p>{buildDate}</p>
    </div>
  );
}
