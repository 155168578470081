import { useQuery } from '@tanstack/react-query';
import { useApiClient } from './useApiClient';

export function useTag(id: string) {
  const apiClient = useApiClient();
  return useQuery({
    queryKey: ['tag', id],
    queryFn: async () => apiClient.tags.get(id),
    enabled: !!id,
  });
}

export function useTagsById(ids?: string[]) {
  const apiClient = useApiClient();
  return useQuery({
    queryKey: ['tag', ids],
    queryFn: async () => {
      if (!ids || ids.length === 0) {
        return [];
      }
      const tags = await apiClient.tags.getMany(ids);
      return tags.filter((tag) => ids.includes(tag.id));
    },
  });
}
