import { ReactNode } from 'react';
import { Spinner } from './Spinner';
import MobilixLogo from './Logo';
import Button from './core/Button';
import { FormattedMessage } from 'react-intl';

interface Props {
  title?: ReactNode;
  text?: ReactNode;
  spinner?: boolean;
  actions?: ReactNode;
  backButton?: boolean;
  reloadButton?: boolean;
}

export function InfoPage({
  title,
  text,
  spinner,
  actions,
  backButton,
  reloadButton,
}: Props) {
  return (
    <div className="flex flex-col items-center gap-8 p-8 sm:gap-16 sm:p-16">
      <MobilixLogo />
      {title && <h2>{title}</h2>}
      {spinner && <Spinner />}
      {text && <p>{text}</p>}
      <div className="flex items-center gap-4">
        {actions}
        {backButton && (
          <Button onClick={() => window.history.back()}>
            <FormattedMessage defaultMessage="Tillbaka" />
          </Button>
        )}
        {reloadButton && (
          <Button onClick={() => window.location.reload()}>
            <FormattedMessage defaultMessage="Ladda om" />
          </Button>
        )}
      </div>
    </div>
  );
}
