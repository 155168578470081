import { useCallback, useEffect, useMemo, useState } from 'react';
import IconButton from './core/IconButton';
import { IconFilter, IconXmark } from '@allbin/icons';
import CheckBox from './core/Checkbox';
import Button from './core/Button';
import { FormattedMessage } from 'react-intl';
import { WorkOrder } from '@/hooks/useWorkOrders';
import { StateName } from './StateName';

interface MapFilterProps {
  workorders: WorkOrder[];
  onChange: (workorders: WorkOrder[]) => void;
  value: WorkOrder[];
}

const WorkOrderMapFilter: React.FC<MapFilterProps> = ({
  workorders,
  onChange,
  value,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const stateTitle = useMemo(() => {
    if (workorders.length === 0) {
      return;
    }
    return StateName({ state: workorders[0].state });
  }, [workorders]);

  const handleWorkorderClick = useCallback(
    (workorder: WorkOrder) => {
      const selectedWorkorders = value.some((wo) => wo.id === workorder.id)
        ? value.filter((wo) => wo.id !== workorder.id)
        : [...value, workorder];

      onChange(selectedWorkorders);
    },
    [value, onChange],
  );

  useEffect(() => {
    onChange([]);
  }, [workorders, onChange]);

  return (
    <div className="absolute right-0 z-10 flex flex-col items-end justify-center px-8  pt-5 ">
      {!isOpen && (
        <>
          <IconButton
            onClick={() => setIsOpen(true)}
            round
            size="lg"
            className="bg-background-50 shadow-md"
            icon={<IconFilter />}
          />
          {value.length > 0 && (
            <div className="absolute top-3 flex size-5 items-center justify-center rounded-full bg-primary-600">
              <span className="text-sm font-medium text-text-100">
                {value.length}
              </span>
            </div>
          )}
        </>
      )}
      {isOpen && (
        <div className="absolute right-3 top-3 z-20">
          <div className=" flex flex-col rounded bg-background-50 p-5 shadow-md">
            <div className="mb-2 flex items-center justify-between pb-2">
              <div className="mr-4 flex flex-row gap-1 font-medium">
                <span>
                  <FormattedMessage defaultMessage="Filtera:" />
                </span>
                <span>{stateTitle}</span>
              </div>
              <IconButton
                round
                onClick={() => setIsOpen(false)}
                icon={<IconXmark />}
              />
            </div>
            <div className="mb-3 flex flex-row gap-2">
              <Button
                onClick={() => onChange(workorders)}
                className="text-nowrap"
              >
                <FormattedMessage defaultMessage="Markera alla" />
              </Button>
              <Button
                disabled={value.length === 0}
                onClick={() => onChange([])}
                className="text-nowrap"
              >
                <FormattedMessage defaultMessage="Avmarkera alla" />
              </Button>
            </div>
            <div className="mt-2 flex max-h-[490px] flex-col gap-3 overflow-y-auto">
              {workorders.map((workorder) => (
                <CheckBox
                  key={workorder.id}
                  id={workorder.id}
                  label={workorder.title}
                  checked={value.some((wo) => wo.id === workorder.id)}
                  onClick={() => handleWorkorderClick(workorder)}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkOrderMapFilter;
