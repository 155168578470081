import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { EntityProps } from '@/types/entities';
import { dateFormat, timeFormat } from '@/utils/dateformat';
import { format } from 'date-fns';

export interface PropertyStoreData {
  last_saved: string | null;
  workorder_id: string;
  data: EntityProps;
}

export interface PropertyFormStore {
  entities: Record<string, PropertyStoreData>;
  checkProperties: (
    property: EntityProps,
    workorderId: string,
  ) => PropertyStoreData;
  updateStorageProperty: (property: PropertyStoreData) => PropertyStoreData;
  deleteStorageProperty: (propertyId: string) => void;
}

export const useEntityFormStore = create<PropertyFormStore>()(
  persist(
    (set, get) => ({
      entities: {},
      checkProperties: (property: EntityProps, workorderId: string) => {
        const existingProperties = get().entities;
        const propertyId = property['meta.id'] as string;
        const existingProperty = existingProperties[propertyId];

        function createNewProperty(): PropertyStoreData {
          return {
            workorder_id: workorderId,
            last_saved: null,
            data: property,
          };
        }

        if (
          !existingProperty ||
          existingProperty.workorder_id !== workorderId
        ) {
          const newProperty = createNewProperty();
          set((state) => ({
            entities: {
              ...state.entities,
              [propertyId]: newProperty,
            },
          }));
          return newProperty;
        }

        return existingProperty;
      },
      updateStorageProperty: (property: PropertyStoreData) => {
        const propertyId = property.data['meta.id'] as string;
        const updatedProperty = {
          ...property,
          last_saved: format(new Date(), `${dateFormat},  ${timeFormat}`),
        };
        set((state) => ({
          entities: {
            ...state.entities,
            [propertyId]: {
              ...state.entities[propertyId],
              workorder_id: property.workorder_id,
              last_saved: format(new Date(), `${dateFormat},  ${timeFormat}`),
              data: property.data,
            },
          },
        }));
        return updatedProperty;
      },
      deleteStorageProperty: (propertyId: string) => {
        console.log('deleteStorageProperty');
        set((state) => ({
          entities: Object.fromEntries(
            Object.entries(state.entities).filter(
              ([key]) => key !== propertyId,
            ),
          ),
        }));
      },
    }),
    {
      name: 'mobilix-entity-form',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
