import { Link, createFileRoute } from '@tanstack/react-router';
import {
  EntityEvent,
  useEntity,
  useEntityEvents,
} from '../../../../../hooks/useEntities';
import { Spinner } from '../../../../../components/Spinner';
import { ErrorPage } from '../../../../../components/ErrorPage';
import { PageHeader } from '../../../../../components/PageHeader';
import { FormattedMessage } from 'react-intl';
import {
  IconBusStop,
  IconChatBubbleSolid,
  IconDotArrowRight,
  IconMediaImage,
  IconMediaImagePlus,
  IconPrivacyPolicy,
  IconUser,
  IconWhiteFlag,
} from '@allbin/icons';
import { cn } from '../../../../../utils/classnames';
import {
  ApiCommentEvent,
  ApiEntityWorkOrderStateEvent,
} from '@allbin/mobilix-api-client';
import { CommentContent } from '../../../workorders/$workorderId/events';
import { StateName } from '../../../../../components/StateName';
import { useWorkOrder } from '../../../../../hooks/useWorkOrders';

export const Route = createFileRoute('/_layout/entities/$entityId/events/')({
  component: EntityDetails,
});

function EntityDetails() {
  const { entityId } = Route.useParams();
  const entity = useEntity(entityId);
  const entityEvents = useEntityEvents(entityId);

  if (entity.isLoading) {
    return (
      <div className="mt-16 flex w-full justify-center">
        <Spinner />
      </div>
    );
  }

  if (entity.isError) {
    return <ErrorPage error={entity.error.message} />;
  }

  if (!entity.data || !entityEvents.data) {
    return (
      <ErrorPage
        title={
          <FormattedMessage defaultMessage="Kunde inte hitta några händelser" />
        }
        error="Hållplatsen har inga händelser i systemet, eller så har du inte behörighet att se dem."
      />
    );
  }

  return (
    <>
      <PageHeader
        subtitle={<FormattedMessage defaultMessage="Händelser" />}
        title={
          <>
            <span>{entity.data.full_name}</span>
            <span className="ml-4 text-lg text-text-600">
              {entity.data.entity_group}-{entity.data.stop_letter}
            </span>
          </>
        }
      />
      <ul className="mt-4 flex flex-col overflow-auto">
        {entityEvents.data.map((event) => (
          <EventItem key={event.id} event={event} />
        ))}
      </ul>
    </>
  );
}

interface EventItemProps {
  event: EntityEvent;
}

function EventItem({ event }: EventItemProps) {
  return (
    <li className="rounded odd:bg-background-100">
      <Link
        disabled={event.type !== 'comment'}
        className="flex items-center gap-4 overflow-hidden p-4"
        to="/entities/$entityId/events"
        params={{ entityId: event.entity_id }}
      >
        <div
          className={cn(
            'size-12 rounded-full p-3',
            event.type === 'workorder:state'
              ? 'bg-primary-500 text-contrast-primary'
              : 'bg-yellow-300 text-contrast-yellow',
          )}
        >
          <EventIcon event={event} />
        </div>
        <div className="flex h-full max-h-28 flex-1 flex-col gap-2 overflow-hidden">
          <span className="text-text-600">
            {event.meta.updated_at.toFormat('yyyy-MM-dd HH:mm')}
          </span>
          <EventContent event={event} />
        </div>
      </Link>
    </li>
  );
}

function EventIcon({ event }: { event: EntityEvent }) {
  switch (event.type) {
    case 'error-report':
      return <IconWhiteFlag />;
    case 'changeset':
      return <IconBusStop />;
    case 'police-report':
      return <IconPrivacyPolicy />;
    case 'comment': {
      const { text, attachments } = event.data as ApiCommentEvent['data'];
      if (text && attachments) {
        return <IconMediaImagePlus />;
      } else if (attachments) {
        return <IconMediaImage />;
      } else {
        return <IconChatBubbleSolid />;
      }
    }
    case 'workorder:state':
      return <IconDotArrowRight />;
    default:
      return <IconUser />;
  }
}

function EventContent({ event }: { event: EntityEvent }) {
  switch (event.type) {
    case 'changeset':
      return (
        <FormattedMessage defaultMessage="Ändringar i hållplatsens egenskaper" />
      );
    case 'error-report':
      return <FormattedMessage defaultMessage="Felanmälan inskickad" />;
    case 'police-report':
      return <FormattedMessage defaultMessage="Polisanmälan inskickad" />;
    case 'comment':
      return (
        <CommentContent
          userId={event.meta.created_by}
          data={event.data as ApiCommentEvent['data']}
        />
      );
    case 'workorder:state':
      return (
        <StateContent
          data={event.data as ApiEntityWorkOrderStateEvent['data']}
        />
      );
    default:
      return (
        <div className="grow">
          <FormattedMessage defaultMessage="Händelsen har ingen innehåll" />
        </div>
      );
  }
}

function StateContent({
  data,
}: {
  data: ApiEntityWorkOrderStateEvent['data'];
}) {
  const workorder = useWorkOrder(data.workorder_id);

  if (workorder.isLoading) {
    return <div className="grow animate-pulse bg-background-200" />;
  }

  if (workorder.isError) {
    return <div className="grow text-text-500">{workorder.error.message}</div>;
  }

  return (
    <>
      <span>
        <FormattedMessage defaultMessage="Arbetsorder" />{' '}
        <StateName state={data.state} />
      </span>
      {workorder.data && (
        <div className="flex flex-wrap items-center gap-2 text-sm font-light">
          <span>{workorder.data.title}</span>
          <span className="ml-2">#{workorder.data.title_suffix}</span>
        </div>
      )}
    </>
  );
}
