import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useState,
} from 'react';
import { useShowAttributes } from '@/hooks/useUserConfig';

export const ThemeCtx = createContext<
  [string, Dispatch<SetStateAction<string>>]
>(['', () => {}]);

const ThemeContext: React.FC<PropsWithChildren> = ({ children }) => {
  const { showTheme } = useShowAttributes();
  const themeState = useState(showTheme);

  return <ThemeCtx.Provider value={themeState}>{children}</ThemeCtx.Provider>;
};

export default ThemeContext;
