import { Link, createFileRoute, useNavigate } from '@tanstack/react-router';
import { useWorkOrder } from '../../../../../hooks/useWorkOrders';
import { Spinner } from '../../../../../components/Spinner';
import { ErrorPage } from '../../../../../components/ErrorPage';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  WorkOrderEvent,
  useWorkOrderEvents,
} from '../../../../../hooks/useWorkOrderEvents';
import {
  IconChatBubble,
  IconChatBubbleSolid,
  IconDotArrowRight,
  IconLabelSolid,
  IconMediaImage,
  IconMediaImagePlus,
  IconMinus,
  IconNavArrowRight,
  IconPage,
  IconPlus,
  IconUser,
} from '@allbin/icons';
import { cn } from '../../../../../utils/classnames';
import {
  ApiCommentEvent,
  ApiWorkOrderChangeSetEvent,
  ApiWorkOrderStateEvent,
  ApiWorkOrderTagEvent,
} from '@allbin/mobilix-api-client';
import { useTagsById } from '../../../../../hooks/useTags';
import { StateName } from '../../../../../components/StateName';
import { useUsers } from '../../../../../hooks/useUsers';
import { useEntity } from '../../../../../hooks/useEntities';
import { useMemo } from 'react';
import IconButton from '@/components/core/IconButton';
import { PageHeader } from '../../../../../components/PageHeader';
import { UserName } from '../../../../../components/UserName';

export const Route = createFileRoute(
  '/_layout/workorders/$workorderId/events/',
)({
  component: WorkOrderEvents,
});

function WorkOrderEvents() {
  const navigate = useNavigate();
  const { workorderId } = Route.useParams();
  const workorder = useWorkOrder(workorderId);
  const workorderEvents = useWorkOrderEvents(workorderId);

  if (workorder.isLoading || workorderEvents.isLoading) {
    return (
      <div className="mt-16 flex w-full justify-center">
        <Spinner />
      </div>
    );
  }

  if (workorder.isError && !workorder.error.message.endsWith('404')) {
    console.error(workorder.error);

    return <ErrorPage error={workorder.error.message} />;
  }

  if (!workorder.data || !workorderEvents.data) {
    return (
      <ErrorPage
        title={
          <FormattedMessage defaultMessage="Kunde inte hitta några händelser" />
        }
        error={`Arbetsorder #${workorderId.substring(0, 6)} har inga händelser i systemet, eller så har du inte behörighet att se dem.`}
      />
    );
  }

  return (
    <>
      <PageHeader
        title={
          <>
            {workorder.data.title}
            <span className="ml-4 text-lg text-text-300">
              #{workorder.data.title_suffix}
            </span>
          </>
        }
        subtitle={<FormattedMessage defaultMessage="Händelser" />}
        action={
          <IconButton
            onClick={() =>
              navigate({
                to: '/workorders/$workorderId/comment',
                params: { workorderId },
              })
            }
            round
            icon={<IconChatBubble />}
          />
        }
      />
      <ul className="mt-4 flex flex-col overflow-auto">
        {workorderEvents.data.map((event) => (
          <EventItem key={event.id} event={event} />
        ))}
      </ul>
    </>
  );
}

interface EventItemProps {
  event: WorkOrderEvent;
}
function EventItem({ event }: EventItemProps) {
  return (
    <li className="odd:bg-primary-50">
      <Link
        disabled={event.type !== 'comment'}
        className="flex items-center gap-4 overflow-hidden p-4"
        to="/workorders/$workorderId/events/$eventId"
        params={{ workorderId: event.workorder_id, eventId: event.id }}
      >
        <div
          className={cn(
            'size-12 rounded-full p-3',
            event.type === 'changeset'
              ? 'bg-yellow-300 text-background-950'
              : 'bg-primary-500 text-text-50',
          )}
        >
          <EventIcon event={event} />
        </div>
        <div className="flex h-full max-h-28 flex-1 overflow-hidden">
          <div className="flex flex-1 flex-col gap-2">
            <span>{event.meta.updated_at.toFormat('yyyy-MM-dd')}</span>
            <EventContent event={event} />
          </div>
          <div className="flex flex-col items-end justify-start">
            <span className="text-sm font-light">
              <FormattedMessage defaultMessage="Utfört av" />
            </span>
            <UserName id={event.meta.created_by} />
          </div>
        </div>
      </Link>
    </li>
  );
}

function EventIcon({ event }: { event: WorkOrderEvent }) {
  switch (event.type) {
    case 'tag':
      return <IconLabelSolid />;
    case 'state':
      return <IconDotArrowRight />;
    case 'changeset':
      return <IconPage />;
    case 'comment':
      if (event.data.text && event.data.attachments) {
        return <IconMediaImagePlus />;
      } else if (event.data.attachments) {
        return <IconMediaImage />;
      } else {
        return <IconChatBubbleSolid />;
      }

    default:
      return <IconUser />;
  }
}

function EventContent({ event }: { event: WorkOrderEvent }) {
  switch (event.type) {
    case 'tag':
      return <TagContent data={event.data} />;
    case 'state':
      return <StateContent data={event.data} />;

    case 'changeset':
      return <ChangesetContent data={event.data} />;
    case 'comment':
      return (
        <CommentContent userId={event.meta.created_by} data={event.data} />
      );
    default:
      return <div className="grow" />;
  }
}

function TagContent({ data }: { data: ApiWorkOrderTagEvent['data'] }) {
  const added = useTagsById(data.tags_added);
  const removed = useTagsById(data.tags_removed);

  if (added.isLoading || removed.isLoading) {
    return <div className="grow animate-pulse bg-background-200" />;
  }

  return (
    <>
      <span className="text-lg font-medium">
        <FormattedMessage defaultMessage="Taggar ändrade" />
      </span>
      <p className="flex flex-wrap gap-2">
        {added.data &&
          added.data.length > 0 &&
          added.data.map((tag) => (
            <span key={tag.id} className="flex items-center text-green-600">
              <IconPlus className="size-4" />
              {tag.name}
            </span>
          ))}
        {removed.data &&
          removed.data.length > 0 &&
          removed.data.map((tag) => (
            <span key={tag.id} className="flex items-center text-red-600">
              <IconMinus className="size-4" />
              {tag.name}
            </span>
          ))}
      </p>
    </>
  );
}

function StateContent({ data }: { data: ApiWorkOrderStateEvent['data'] }) {
  return (
    <>
      <span className="text-lg font-medium">
        <FormattedMessage defaultMessage="Arbetsorder" />{' '}
        <StateName state={data.state} />
      </span>
      <span className="flex flex-wrap items-center gap-2 text-sm font-light">
        {data.prev_state && (
          <>
            <StateName state={data.prev_state} />
            <IconNavArrowRight className="size-4" />
          </>
        )}
        <StateName state={data.state} />
      </span>
    </>
  );
}

function ChangesetContent({
  data,
}: {
  data: ApiWorkOrderChangeSetEvent['data'];
}) {
  const entity = useEntity(data.entity_id);

  if (entity.isLoading) {
    return <div className="grow animate-pulse bg-background-200" />;
  }

  if (entity.isError || !entity.data) {
    return (
      <div className="grow">
        <FormattedMessage defaultMessage="Inventering inskickad för okänd" />
      </div>
    );
  }

  return (
    <>
      <span className="text-lg font-medium">
        <FormattedMessage
          defaultMessage="Inventering inskickad för {entity}"
          values={{ entity: entity.data.full_name }}
        />
      </span>
      <span className="text-sm font-light">
        {entity.data.entity_group}-{entity.data.stop_letter}
      </span>
    </>
  );
}

export function CommentContent({
  userId,
  data,
}: {
  userId: string;
  data: ApiCommentEvent['data'];
}) {
  const intl = useIntl();
  const user = useUsers([userId]);

  const name = useMemo(() => {
    if (!user.data || !user.data[0]) {
      return intl.formatMessage({ defaultMessage: 'Okänd' });
    }

    return user.data[0].name;
  }, [intl, user.data]);

  if (user.isLoading) {
    return <div className="grow animate-pulse bg-background-200" />;
  }

  if (data.text && data.attachments) {
    return (
      <>
        <span className="text-lg font-medium">
          <FormattedMessage
            defaultMessage="{name} kommenterade med bild"
            values={{ name }}
          />
        </span>
        <span className="line-clamp-1">{data.text}</span>
      </>
    );
  }

  if (data.attachments) {
    return (
      <span className="text-lg font-medium">
        <FormattedMessage
          defaultMessage="{name} laddade upp en bild"
          values={{ name }}
        />
      </span>
    );
  }

  return (
    <>
      <span className="text-lg font-medium">
        <FormattedMessage
          defaultMessage="{name} kommenterade"
          values={{ name }}
        />
      </span>
      <span className="line-clamp-1">{data.text}</span>
    </>
  );
}
