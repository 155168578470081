import { ReactNode, createContext, useEffect, useState } from 'react';

export interface GeolocationData {
  position?: GeolocationCoordinates;
  permission: PermissionState;
}

export const GeolocationContext = createContext<GeolocationData>({
  position: undefined,
  permission: 'prompt',
});

export function GeolocationProvider({ children }: { children: ReactNode }) {
  const [position, setPosition] = useState<GeolocationCoordinates>();
  const [permission, setPermission] = useState<PermissionState>('prompt');

  useEffect(() => {
    const watchId = navigator.geolocation.watchPosition(
      (pos) => {
        setPermission('granted');
        setPosition(pos.coords);
      },
      (err) => {
        console.error(err);
        switch (err.code) {
          case GeolocationPositionError.PERMISSION_DENIED:
            setPermission('denied');
            break;
          case GeolocationPositionError.POSITION_UNAVAILABLE:
            setPermission('granted');
            setPosition(undefined);
            break;
        }
      },
    );

    return () => {
      navigator.geolocation.clearWatch(watchId);
    };
  }, []);

  return (
    <GeolocationContext.Provider value={{ position, permission }}>
      {children}
    </GeolocationContext.Provider>
  );
}
