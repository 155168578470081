import { useApiClient } from '../hooks/useApiClient';
import { ApiWorkOrder, ApiWorkOrderRequest } from '@allbin/mobilix-api-client';
import { ReviverFn } from '../utils/revivers';
import { DateTime } from 'luxon';
import { Metadata } from '../types/metadata';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';

export interface WorkOrder
  extends Omit<ApiWorkOrder, 'tenant_id' | 'meta' | 'due_at'> {
  title_suffix: string;
  due_at?: DateTime;
  meta: Metadata;
}

export interface WorkOrderRequest extends Omit<ApiWorkOrderRequest, 'due_at'> {
  id?: string;
  due_at?: DateTime;
}

const reviveWorkOrder: ReviverFn<ApiWorkOrder, WorkOrder> = (workOrder) => ({
  ...workOrder,
  title_suffix: workOrder.id.substring(0, 6),
  due_at: workOrder.due_at ? DateTime.fromISO(workOrder.due_at) : undefined,
  meta: {
    created_at: DateTime.fromISO(workOrder.meta.created_at),
    created_by: 'auth0|6172bc995cd96b0070339cc7',
    updated_at: DateTime.fromISO(workOrder.meta.updated_at),
  },
});

export function useWorkOrders() {
  const { isAuthenticated } = useAuth0();
  const client = useApiClient();
  return useQuery({
    queryKey: ['workOrders', 'list'],
    queryFn: async () => {
      try {
        const workOrders = await client.workOrders.list();
        return workOrders.map(reviveWorkOrder);
      } catch (error) {
        console.error('Error fetching work orders', error);
        throw error;
      }
    },
    enabled: isAuthenticated,
  });
}

export function useWorkOrder(id: string) {
  const { isAuthenticated } = useAuth0();
  const client = useApiClient();
  return useQuery({
    queryKey: ['workOrders', id],
    queryFn: async () => {
      try {
        const workOrder = await client.workOrders.get(id);
        return reviveWorkOrder(workOrder);
      } catch (error) {
        console.error('Error fetching work orders', error);
        throw error;
      }
    },
    enabled: isAuthenticated,
  });
}

const packWorkOrderRequest = (
  workOrder: WorkOrderRequest,
): ApiWorkOrderRequest => ({
  ...workOrder,
  due_at: workOrder.due_at?.toISO() || undefined,
});

export function useWorkOrderUpdate() {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['workOrders', 'update'],
    mutationFn: async (workOrderRequest: WorkOrderRequest) => {
      if (!workOrderRequest.id) {
        throw new Error('WorkOrder id is required to update');
      }
      const updatedWorkOrder = await client.workOrders.update(
        workOrderRequest.id,
        packWorkOrderRequest(workOrderRequest),
      );
      return reviveWorkOrder(updatedWorkOrder);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['workOrders'] });
    },
  });
}

export function useWorkOrderCreate() {
  const client = useApiClient();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['workOrders', 'create'],
    mutationFn: async (workOrderRequest: WorkOrderRequest) => {
      const createdWorkOrder = await client.workOrders.create(
        packWorkOrderRequest(workOrderRequest),
      );
      return reviveWorkOrder(createdWorkOrder[0]);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['workOrders'] });
    },
  });
}
