import { UseControllerProps, useController } from 'react-hook-form';
import { FormValues } from '../EntityPropertyList';
import Input from '@/components/core/Input';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

export function NumberInput(props: UseControllerProps<FormValues>) {
  const intl = useIntl();
  const { field } = useController(props);
  const inputRef = useRef<HTMLInputElement>(null);

  const value = useMemo(() => {
    return field.value as number | null;
  }, [field.value]);

  const [validValue, setValidValue] = useState<string | null>(
    value?.toString() ?? null,
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const parsedValue = e.target.value.replace(',', '.');
      if (!isNaN(Number(parsedValue))) {
        setValidValue(parsedValue);
      }
    },
    [],
  );

  const onBlur = useCallback(() => {
    field.onChange(Number(validValue));
  }, [validValue, field]);

  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        inputRef.current?.blur();
      }
    },
    [],
  );

  return (
    <Input
      ref={inputRef}
      id={field.name}
      type="text"
      inputMode="numeric"
      pattern="\\d*\\.?\\d*"
      enterKeyHint="done"
      onKeyDown={handleOnKeyDown}
      value={validValue ?? ''}
      onChange={onChange}
      placeholder={intl.formatMessage({ defaultMessage: 'Saknas' })}
      className="w-40"
      onBlur={onBlur}
    />
  );
}
