import { create } from 'zustand';
import { ApiEntitySchema } from '@allbin/mobilix-api-client';
import { EntityProps } from '@/types/entities';
import { ImageRowProps } from '@/components/EntityProp/EntityPropertyList';

interface ImageState {
  images: ImageRowProps[];
  setImages: (shema: ApiEntitySchema, properties: EntityProps) => void;
}

export const useImagesFormStore = create<ImageState>((set) => ({
  images: [],
  setImages: (schema, properties) => {
    const newImages = schema.definition.properties
      .filter((property) => property.type === 'photo')
      .map((property) => ({
        name: property.name,
        propertyKey: property.key.replace('.', '#'),
        imageId: (properties[property.key] as string) || null,
      }));
    set({ images: newImages });
  },
}));
