import { Entity } from '@/types/entities';
import { useMemo } from 'react';
import { collator } from '@/utils/collator';

interface EntityGroup {
  id: string;
  name: string;
  entities: Entity[];
}

export const useEntityGroups = (entities: Entity[]) => {
  return useMemo(() => {
    const groupLookup = (entities || []).reduce<Record<string, EntityGroup>>(
      (acc, entity) => {
        const groupId = entity.entity_group;
        const groupName = entity.properties['meta.name'] as string;

        if (!acc[groupId]) {
          acc[groupId] = {
            id: groupId,
            name: groupName,
            entities: [],
          };
        }

        acc[groupId].entities.push(entity);

        return acc;
      },
      {},
    );

    // Sort entities by name
    const groups = Object.values(groupLookup).sort((a, b) =>
      collator.compare(a.name, b.name),
    );
    groups.forEach((group) => {
      group.entities.sort((a, b) => {
        return collator.compare(
          a.properties['meta.name'] as string,
          b.properties['meta.name'] as string,
        );
      });
    });

    return groups;
  }, [entities]);
};
