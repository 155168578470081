import Button from '@/components/core/Button';
import { ErrorPage } from '@/components/ErrorPage';
import { PageHeader } from '@/components/PageHeader';
import { Spinner } from '@/components/Spinner';
import { useCheckInPlan, useCreateCheckIn } from '@/hooks/useCheckIn';
import { useEntitiesById } from '@/hooks/useEntities';
import { useErrorReports } from '@/hooks/useErrorReport';
import { cn } from '@/lib/utils';
import { Entity } from '@/types/entities';
import { IconPin, IconWhiteFlagSolid } from '@allbin/icons';
import {
  PeriodicityPeriodStatus,
  getPeriodStatus,
} from '@allbin/mobilix-utils';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { DateTime } from 'luxon';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/dropdown';
import { DropdownMenu } from '@/components/dropdown';
import { DropdownMenuLabel } from '@radix-ui/react-dropdown-menu';
import { useEntitySchema } from '@/hooks/useEntitySchema';
import { collator } from '@/utils/collator';
import { ApiEntitySchemaProp } from '@allbin/mobilix-api-client';

export const Route = createFileRoute('/_layout/checkins/$checkinId')({
  component: CheckInView,
});

interface EntityWithStatus extends Entity {
  status: PeriodicityPeriodStatus;
}

interface CheckInEntities {
  completed: EntityWithStatus[];
  remaining: EntityWithStatus[];
}

function CheckInView() {
  const { checkinId } = Route.useParams();
  const checkin = useCheckInPlan(checkinId);
  const entities = useEntitiesById(checkin.data?.entities);
  const entitySchema = useEntitySchema();

  const reportableProps = useMemo<string[]>(() => {
    if (!entitySchema.data?.extras) {
      return [];
    }
    return entitySchema.data.definition.properties
      .filter(
        (prop) =>
          entitySchema.data.extras?.[prop.key]?.support_urls?.['default'],
      )
      .map((prop) => prop.key);
  }, [entitySchema.data]);

  const { remaining, completed } = useMemo(() => {
    const result: CheckInEntities = {
      completed: [],
      remaining: [],
    };

    if (!checkin.data || !entities.data) {
      return result;
    }

    entities.data.forEach((entity) => {
      const status = getPeriodStatus(checkin.data.activePeriod, [
        ...(entity.checkins?.contractor || []),
        ...(entity.checkins?.admin || []),
      ]);

      if (status.executed === 'missed') {
        // missed
        result.remaining.push({ ...entity, status });
      } else {
        // completed
        result.completed.push({ ...entity, status });
      }
    });

    result.remaining.sort((a, b) => collator.compare(a.full_name, b.full_name));
    result.completed.sort((a, b) => collator.compare(a.full_name, b.full_name));

    return result;
  }, [checkin.data, entities.data]);

  if (checkin.isLoading || entities.isLoading) {
    return (
      <div className="mt-16 flex w-full justify-center">
        <Spinner />
      </div>
    );
  }

  if (checkin.isError && !checkin.error.message.endsWith('404')) {
    console.error(checkin.error);

    return <ErrorPage error={checkin.error.message} />;
  }

  if (!checkin.data) {
    return (
      <ErrorPage
        title={
          <FormattedMessage defaultMessage="Tillsynsplanen kunde inte hittas" />
        }
        error={`Tillsynsplan #${checkinId.substring(0, 6)} finns inte i systemet, eller så har du inte behörighet att se den.`}
      />
    );
  }

  return (
    <>
      <PageHeader title={checkin.data?.title} />
      <div className="flex flex-col gap-4">
        {remaining.length > 0 && (
          <>
            <h3>Kvar att göra</h3>
            <ul className="flex flex-col gap-4">
              {remaining.map((entity) => (
                <li key={entity.id}>
                  <EntityItem
                    entity={entity}
                    reportableProps={reportableProps}
                  />
                </li>
              ))}
            </ul>
          </>
        )}
        {remaining.length > 0 && completed.length > 0 && (
          <hr className="border-background-300" />
        )}
        {completed.length > 0 && (
          <>
            <h3>Utfört</h3>
            <ul className="flex flex-col gap-4">
              {completed.map((entity) => (
                <li key={entity.id}>
                  <EntityItem
                    entity={entity}
                    reportableProps={reportableProps}
                  />
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </>
  );
}

interface EntityItemProps {
  entity: EntityWithStatus;
  reportableProps: string[];
}

function EntityItem({ entity, reportableProps }: EntityItemProps) {
  const navigate = useNavigate();
  const entitySchema = useEntitySchema();

  const errorReports = useErrorReports(entity.id);
  const createCheckIn = useCreateCheckIn();

  const lastCheckIn = useMemo<DateTime | undefined>(() => {
    // get latest checkin
    const checkins = [
      ...(entity.checkins?.contractor.map((c) => c.timestamp) || []),
      ...(entity.checkins?.admin.map((c) => c.timestamp) || []),
    ];
    if (checkins.length === 0) {
      return;
    }

    return checkins.sort((a, b) => b.toMillis() - a.toMillis())[0];
  }, [entity.checkins]);

  const handleCreateCheckIn = useCallback(async () => {
    await createCheckIn.mutateAsync(entity.id);
  }, [createCheckIn, entity.id]);

  const handleCreateWorkOrder = useCallback(async () => {
    await handleCreateCheckIn();

    navigate({
      to: '/create_workorder',
      search: {
        entities: [entity.id],
      },
    });
  }, [entity.id, handleCreateCheckIn, navigate]);

  const errorReportProps = useMemo(() => {
    return (
      entitySchema.data?.definition.properties.filter((prop) =>
        reportableProps.includes(prop.key),
      ) || []
    );
  }, [entitySchema.data?.definition.properties, reportableProps]);

  const handleErrorReport = useCallback(
    async (propertyId: string) => {
      await handleCreateCheckIn();
      navigate({
        to: '/entities/$entityId/error_report',
        params: { entityId: entity.id },
        search: {
          propertyId,
        },
      });
    },
    [entity.id, handleCreateCheckIn, navigate],
  );

  return (
    <div className="flex flex-col gap-4 rounded bg-background-100 p-2 lg:flex-row">
      <div className="flex grow items-center gap-4">
        <IconPin
          className={cn(
            'size-6',
            entity.status.executed === 'on_time'
              ? 'text-green-500'
              : 'text-yellow-500',
          )}
        />
        {errorReports.data && errorReports.data.length > 0 && (
          <IconWhiteFlagSolid className="size-6 text-red-600" />
        )}
        <div className="flex flex-col">
          <p>
            {entity.full_name} {entity.properties['meta.id']}
          </p>
          {lastCheckIn && (
            <div className="flex gap-2 italic text-text-600">
              <span>
                <FormattedMessage defaultMessage="Senaste tillsyn" />
              </span>
              <span>{lastCheckIn.toFormat('yyyy-MM-dd HH:mm')}</span>
            </div>
          )}
        </div>
      </div>
      {entity.status.executed === 'missed' ? (
        <ActionButtons
          entity={entity}
          errorReportProps={errorReportProps}
          handleCreateCheckIn={handleCreateCheckIn}
          handleCreateWorkOrder={handleCreateWorkOrder}
          handleErrorReport={handleErrorReport}
        />
      ) : null}
    </div>
  );
}

interface ActionButtonsProps {
  entity: EntityWithStatus;
  errorReportProps: ApiEntitySchemaProp[];
  handleCreateCheckIn: () => void;
  handleCreateWorkOrder: () => void;
  handleErrorReport: (propertyId: string) => void;
}
const ActionButtons: React.FC<ActionButtonsProps> = ({
  entity,
  errorReportProps,
  handleCreateCheckIn,
  handleCreateWorkOrder,
  handleErrorReport,
}) => {
  return (
    <div className="flex items-stretch justify-end gap-4">
      <Button variant="filled" color="green" onClick={handleCreateCheckIn}>
        <FormattedMessage defaultMessage="Ingen anmärkning" />
      </Button>
      <Button
        variant={entity.status.executed === 'missed' ? 'filled' : 'outline'}
        color="base"
        onClick={handleCreateWorkOrder}
      >
        <FormattedMessage defaultMessage="Skapa arbetsorder" />
      </Button>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant={entity.status.executed === 'missed' ? 'filled' : 'outline'}
            color="red"
          >
            <FormattedMessage defaultMessage="Felanmäl" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="flex max-h-96 w-56 flex-col gap-2 overflow-auto">
          <DropdownMenuLabel>
            <FormattedMessage defaultMessage="Felanmäl attribut" />
          </DropdownMenuLabel>
          {errorReportProps.map((prop) => (
            <DropdownMenuItem key={prop.key} asChild>
              <Button
                className="w-full !justify-start"
                variant="ghost"
                onClick={() => handleErrorReport(prop.key)}
              >
                {prop.name}
              </Button>
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};
