import { Entity } from '@/types/entities';
import { ApiEntitySchema } from '@allbin/mobilix-api-client';

export const filterProperties = (
  properties: Entity['properties'],
  schema: ApiEntitySchema,
): Entity['properties'] =>
  Object.keys(properties).reduce<Entity['properties']>((acc, key) => {
    if (key.startsWith('derived')) {
      return acc;
    }

    // Skip unknown properties
    if (!schema.definition.properties.some((prop) => prop.key === key)) {
      return acc;
    }

    acc[key] = properties[key];
    return acc;
  }, {});
