import { useQuery } from '@tanstack/react-query';
import { useApiClient } from './useApiClient';
import { useAuth0 } from '@auth0/auth0-react';

export function useUsers(ids: string[]) {
  const apiClient = useApiClient();
  return useQuery({
    queryKey: ['user', ids],
    queryFn: async () => {
      try {
        const users = await apiClient.users.list(ids);
        return users;
      } catch (error) {
        console.error('Error fetching user', error);
        throw error;
      }
    },
    enabled: ids.length > 0,
  });
}

export const useCurrentUser = () => {
  const { user } = useAuth0();
  const users = useUsers(user?.sub ? [user.sub] : []);

  return { ...users, data: users.data?.[0] };
};
