import { UseControllerProps, useController } from 'react-hook-form';
import { FormValues } from '../EntityPropertyList';
import Input from '@/components/core/Input';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

export function StringInput(props: UseControllerProps<FormValues>) {
  const intl = useIntl();
  const { field } = useController(props);

  const value = useMemo(() => {
    return field.value as string | null;
  }, [field.value]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (e.target.value === '') {
        return field.onChange(null);
      }
      field.onChange(e.target.value);
    },
    [field],
  );

  return (
    <Input
      type="multiline"
      value={value ?? ''}
      onChange={handleChange}
      placeholder={intl.formatMessage({ defaultMessage: 'Saknas' })}
      className="min-w-[320px]"
    />
  );
}
