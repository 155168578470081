import {
  createRootRouteWithContext,
  Outlet,
  redirect,
  ScrollRestoration,
} from '@tanstack/react-router';
import { Auth0ContextInterface, useAuth0, User } from '@auth0/auth0-react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useEntitySchema } from '../hooks/useEntitySchema';
import { useEntityTypes } from '../hooks/useEntityTypes';
import { useCurrentUser } from '../hooks/useUsers';
import { PWAUpdatePrompt } from '../components/PWAUpdatePrompt';
import { ImagePickerProvider } from '@/components/EntityProp/ImagePickerProvider';
import { useContext } from 'react';
import { ThemeCtx } from '@/contexts/ThemeContext';
import { cn } from '@/utils/classnames';

interface RouterContext {
  organizationId?: string;
  auth?: Auth0ContextInterface<User>;
  token?: string;
}

const publicRoutes = ['/login', '/callback'];

export const Route = createRootRouteWithContext<RouterContext>()({
  component: Root,
  beforeLoad: async ({ context, location }) => {
    if (publicRoutes.includes(location.pathname)) {
      return;
    }

    if (!context.auth) {
      // this is a bug
      throw new Error('context is missing auth, this should not happen');
    }

    if (!context.auth.isAuthenticated) {
      throw redirect({
        to: '/login',
        search: {
          redirect: location.pathname,
        },
      });
    }
  },
});

function Root() {
  const [theme] = useContext(ThemeCtx);
  const { isLoading } = useAuth0();

  useEntitySchema();
  useEntityTypes();
  useCurrentUser();

  if (isLoading) {
    return null;
  }

  return (
    <div
      className={cn(
        theme,
        'relative size-full overflow-hidden bg-background-50 text-text-900',
      )}
    >
      <ScrollRestoration />
      <ImagePickerProvider>
        <Outlet />
      </ImagePickerProvider>
      <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-right" />
      <PWAUpdatePrompt />
    </div>
  );
}
