import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { router } from './router';
import { RouterProvider } from '@tanstack/react-router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { useConfig } from './hooks/useConfig';
import { APIProvider } from '@vis.gl/react-google-maps';
import { GeolocationProvider } from './contexts/GeolocationContext';
import { InfoPage } from './components/InfoPage';
import Toast from './components/core/Toast';

import 'react-toastify/dist/ReactToastify.min.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

function Router() {
  const auth = useAuth0();
  if (auth.isLoading) {
    return null;
  }
  return <RouterProvider router={router} context={{ auth }} />;
}

function Main() {
  const {
    query: { isLoading, isError },
    mapsApiKey,
    auth,
  } = useConfig();

  if (isLoading) {
    return (
      <InfoPage spinner title={<FormattedMessage defaultMessage="Laddar" />} />
    );
  }

  if (isError) {
    return (
      <InfoPage
        title={<FormattedMessage defaultMessage="Ett fel uppstod" />}
        reloadButton
      />
    );
  }

  return (
    <Auth0Provider
      domain={auth.domain}
      clientId={auth.clientId}
      authorizationParams={{
        audience: auth.audience,
        redirect_uri: window.location.origin + '/callback',
      }}
      cacheLocation="localstorage"
      useRefreshTokens
    >
      <APIProvider apiKey={mapsApiKey} libraries={['marker']}>
        <GeolocationProvider>
          <Router />
          <Toast />
        </GeolocationProvider>
      </APIProvider>
    </Auth0Provider>
  );
}

function App() {
  return (
    <IntlProvider messages={{}} locale="sv" defaultLocale="sv">
      <QueryClientProvider client={queryClient}>
        <Main />
      </QueryClientProvider>
    </IntlProvider>
  );
}

export default App;
