import Button from './core/Button';

interface ListButtonProps {
  columnLeft?: React.ReactNode;
  columnRight?: React.ReactNode;
  onClick?: () => void;
}

export function ListButton({
  columnLeft,
  columnRight,
  onClick,
}: ListButtonProps) {
  return (
    <Button
      onClick={onClick}
      className="h-auto w-full border-background-300 py-4 hover:bg-background-200"
    >
      <div className="flex w-full flex-row gap-4">
        <div className="flex h-auto w-full flex-col justify-center overflow-hidden">
          {columnLeft}
        </div>
        <div className="flex h-auto flex-col justify-center">{columnRight}</div>
      </div>
    </Button>
  );
}
