import {
  GeolocationContext,
  GeolocationData,
} from '@/contexts/GeolocationContext';
import { useContext } from 'react';

export function useGeolocation(): GeolocationData {
  const { permission, position } = useContext(GeolocationContext);
  return { permission, position };
}
