import { PageHeader } from '@/components/PageHeader';
import {
  createFileRoute,
  useNavigate,
  ReactNode,
} from '@tanstack/react-router';
import { FormattedMessage } from 'react-intl';
import { useAuth0 } from '@auth0/auth0-react';
import { IconLogOut } from '@allbin/icons';
import { HelpDialog } from '@/components/HelpDialog';
import Button from '@/components/core/Button';
import { useCallback } from 'react';
import { useContext } from 'react';
import { ThemeCtx } from '@/contexts/ThemeContext';
import { useShowAttributes } from '@/hooks/useUserConfig';

export const Route = createFileRoute('/_layout/menu')({
  component: Menu,
});

function Menu() {
  return (
    <>
      <PageHeader
        title={<FormattedMessage defaultMessage="Meny" />}
        action={<HelpDialog />}
      />
      <div className="flex w-full flex-col gap-8">
        <UserInfo />
        <Settings />
        <Logout />
      </div>
    </>
  );
}

function UserInfo() {
  const { user } = useAuth0();

  return (
    <div className="flex flex-col">
      <Header>
        <FormattedMessage defaultMessage="Inloggad som" />
      </Header>
      <span>{user?.name}</span>
      <span className="text-sm text-text-500">{user?.email}</span>
    </div>
  );
}

function Settings() {
  const { toggleTheme } = useShowAttributes();
  const [theme, setTheme] = useContext(ThemeCtx);

  const changeTheme = useCallback(
    (theme: string) => {
      setTheme(theme);
      toggleTheme(theme);
    },
    [toggleTheme, setTheme],
  );

  return (
    <div className="flex flex-col border-t border-background-300 pt-8">
      <Header>
        <FormattedMessage defaultMessage="Inställningar" />
      </Header>
      <FormattedMessage defaultMessage="Använd mörkt tema" />
      <div className="isolate mt-2 inline-flex rounded-md">
        <Button
          variant={theme === 'default dark' ? 'filled' : 'outline'}
          color={theme === 'default dark' ? 'green' : 'base'}
          className="rounded-r-none border-r-0"
          onClick={() => changeTheme('default dark')}
        >
          <FormattedMessage defaultMessage="Ja" />
        </Button>
        <Button
          variant={theme === 'default' ? 'filled' : 'outline'}
          color={theme === 'default' ? 'red' : 'base'}
          className="rounded-l-none"
          onClick={() => changeTheme('default')}
        >
          <FormattedMessage defaultMessage="Nej" />
        </Button>
      </div>
    </div>
  );
}

function Logout() {
  const navigate = useNavigate();

  return (
    <div className="border-t border-background-300 pt-8">
      <Button onClick={() => navigate({ to: '/logout' })}>
        <FormattedMessage defaultMessage="Logga ut" />
        <IconLogOut className="size-5" />
      </Button>
    </div>
  );
}

function Header({ children }: ReactNode) {
  return <p className="mb-2 text-xs uppercase text-text-700">{children}</p>;
}
