import { Link, RoutesByPath } from '@tanstack/react-router';
import {
  IconBusStop,
  IconEye,
  IconGoogleDocs,
  IconHome,
  IconMenu,
  IconPlus,
} from '@allbin/icons';
import { FormattedMessage } from 'react-intl';
import { router } from '../router';
import { useMemo } from 'react';
import { cn } from '@/lib/utils';
import { useOngoingCheckinEntities } from '@/hooks/useCheckinStatus';

export function NavBar() {
  const ongoingCheckins = useOngoingCheckinEntities();

  const checkinCount = useMemo(() => {
    return ongoingCheckins.filter(
      (checkin) => checkin.remainingEntities.length > 0,
    ).length;
  }, [ongoingCheckins]);

  return (
    <nav className="flex h-20 min-h-20 w-full items-center justify-center bg-primary-500">
      <div className="flex h-full items-center gap-6">
        <NavLink to="/" icon={<IconHome />}>
          <FormattedMessage defaultMessage="Start" />
        </NavLink>
        <NavLink to="/workorders" icon={<IconGoogleDocs />}>
          <FormattedMessage defaultMessage="Ordrar" />
        </NavLink>
        <NavLink to="/create_workorder" icon={<IconPlus />}>
          <FormattedMessage defaultMessage="Ny Order" />
        </NavLink>
        <NavLink to="/entities" icon={<IconBusStop />}>
          <FormattedMessage defaultMessage="Hållplatser" />
        </NavLink>
        <NavLink to="/checkins" icon={<IconEye />}>
          <span
            className={cn(
              'absolute right-0 top-0 flex size-5 items-center justify-center rounded-full bg-red-600 text-xs font-bold text-red-100',
              !checkinCount && 'hidden',
            )}
          >
            {checkinCount}
          </span>
          <FormattedMessage defaultMessage="Tillsyn" />
        </NavLink>
        <NavLink to="/menu" icon={<IconMenu />}>
          <FormattedMessage defaultMessage="Meny" />
        </NavLink>
      </div>
    </nav>
  );
}

interface NavLinkProps {
  to: keyof RoutesByPath<typeof router.routeTree>;
  icon: React.ReactNode;
  children: React.ReactNode;
  exact?: boolean;
}

function NavLink({ to, icon, children, exact }: NavLinkProps) {
  return (
    <Link
      to={to as string}
      activeOptions={{
        exact,
      }}
      className="relative flex min-w-14 flex-col items-center justify-center gap-1 text-nowrap rounded border-b-4 border-transparent p-2 text-contrast-primary active:bg-black/10 [&.active]:border-primary-800"
    >
      <div className="size-4 md:size-6">{icon}</div>
      <p className="text-xs font-bold md:text-base">{children}</p>
    </Link>
  );
}
