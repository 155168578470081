import { useMemo } from 'react';
import { createFileRoute } from '@tanstack/react-router';
import { useQuery } from '@tanstack/react-query';
import { MobilixApiClient } from '@allbin/mobilix-api-client';
import { useConfig } from '../hooks/useConfig';

export const Route = createFileRoute('/tenants')({
  component: Tenants,
});

function Tenants() {
  const { baseUrl } = useConfig();
  const apiClient = useMemo(
    () =>
      MobilixApiClient({
        baseUrl,
      }),
    [baseUrl],
  );
  const tenants = useQuery({
    queryKey: ['tenants'],
    queryFn: () => apiClient.tenants.list(),
  });

  return (
    <div className="p-2">
      <h3>Tenants</h3>
      {tenants.data?.map((tenant) => <div key={tenant.id}>{tenant.name}</div>)}
    </div>
  );
}
