import { useCallback, useMemo, useState } from 'react';
import { UseControllerProps, useController } from 'react-hook-form';
import Button from '@/components/core/Button';
import { FormValues } from '../EntityPropertyList';
import { useUserProfile } from '@/hooks/useProfile';
import { GeolocationData } from '@/contexts/GeolocationContext';
import { Map, AdvancedMarker, useMap } from '@vis.gl/react-google-maps';
import { MapPinIcon } from '@heroicons/react/24/solid';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from '@/components/dialog';
import { locationPropertyToString } from '@/utils/formatting_helpers';
import { FormattedMessage } from 'react-intl';
import { Circle } from './Circle';
import { IconBusStop, IconGps } from '@allbin/icons';
import { useGeolocation } from '@/hooks/useGeolocation';

export function LocationInput(props: UseControllerProps<FormValues>) {
  const { data: user, isLoading } = useUserProfile();
  const [open, setOpen] = useState(false);

  const { field } = useController(props);

  const value = useMemo<[number, number] | null>(() => {
    if (!field.value) {
      return null;
    }
    if (!Array.isArray(field.value) || field.value.length !== 2) {
      console.error('Invalid location value', field.value);
      return null;
    }

    return [field.value[0] as number, field.value[1] as number];
  }, [field.value]);

  const [x, setX] = useState(value?.[0] || 0);
  const [y, setY] = useState(value?.[1] || 0);

  const [dragging, setDragging] = useState(false);

  const handleCancel = useCallback(() => {
    setX(value?.[0] || 0);
    setY(value?.[1] || 0);
    setOpen(false);
  }, [value, setOpen, setX, setY]);

  const handleSave = useCallback(() => {
    field.onChange([x, y]);
    setOpen(false);
  }, [field, x, y]);

  const { position, permission } = useGeolocation();

  const map = useMap();

  if (isLoading || !user) {
    // TODO skeleton
    return null;
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>
          {value ? (
            locationPropertyToString(value, user.projection)
          ) : (
            <FormattedMessage defaultMessage="Mät in" />
          )}
        </Button>
      </DialogTrigger>
      <DialogContent className="max-h-full max-w-full overflow-auto">
        <DialogHeader>
          <h3>
            <FormattedMessage defaultMessage="Mät in GPS koordinater" />
          </h3>
          <h5>
            <FormattedMessage defaultMessage="Använd enhetens position, eller flytta markören manuellt på kartan" />
          </h5>
        </DialogHeader>
        <div className="flex flex-col gap-2">
          <Map
            className="max-h-96 min-h-96 w-full"
            mapId="ed6ae4b48b089819"
            defaultCenter={{
              lat: y || 59.2718832,
              lng: x || 15.208291,
            }}
            defaultZoom={17}
            gestureHandling="greedy"
            keyboardShortcuts={false}
            streetViewControl={false}
          >
            {value && (
              <AdvancedMarker
                draggable
                position={{
                  lat: y,
                  lng: x,
                }}
                onDragStart={() => {
                  setDragging(true);
                }}
                onDrag={(event) => {
                  if (!event.latLng) return;
                  setX(event.latLng.lng());
                  const zoom = map?.getZoom() ?? 0;
                  const baseOffset = 0.0002;
                  const zoomFactor = Math.pow(2, 18 - zoom);
                  const dynamicOffset = baseOffset * zoomFactor;
                  setY(event.latLng.lat() + dynamicOffset);
                }}
                onDragEnd={() => {
                  setDragging(false);
                }}
              >
                <div
                  className={`flex h-[120px] w-16 justify-center  ${
                    dragging ? 'items-start' : 'items-end'
                  }`}
                >
                  <MapPinIcon className="size-12 text-red-600" />
                </div>
              </AdvancedMarker>
            )}
            {position && (
              <Circle
                center={{
                  lat: position.latitude,
                  lng: position.longitude,
                }}
                radius={position.accuracy}
                strokeColor="#1d4ed8"
                strokeOpacity={0.6}
                fillColor="#1d4ed8"
              />
            )}
          </Map>
          <div className="flex flex-col justify-between gap-2 sm:flex-row sm:items-center">
            <div className="flex gap-2 text-sm text-gray-500">
              <IconGps className="size-5" />
              <GeolocationStatus position={position} permission={permission} />
            </div>
            <div className="flex gap-2 text-sm text-gray-500">
              <IconBusStop className="size-5" />
              {locationPropertyToString([x, y], user.projection)}
            </div>
          </div>
        </div>
        <DialogFooter className="gap-y-2">
          <Button
            onClick={() => {
              if (!map || !position) return;

              setX(position.longitude);
              setY(position.latitude);
              map.panTo({
                lat: position.latitude,
                lng: position.longitude,
              });
            }}
            disabled={!position}
          >
            <FormattedMessage defaultMessage="Använd min position" />
          </Button>
          <div className="grow" />
          <Button onClick={handleCancel}>
            <FormattedMessage defaultMessage="Avbryt" />
          </Button>
          <Button onClick={handleSave} variant="filled">
            <FormattedMessage defaultMessage="Spara" />
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function GeolocationStatus({ position, permission }: GeolocationData) {
  const { data: user } = useUserProfile();
  const accuracy = useMemo(() => {
    if (!position?.accuracy) return null;

    return Math.round(position.accuracy);
  }, [position?.accuracy]);

  if (permission === 'denied') {
    return <FormattedMessage defaultMessage="Platsdelning är avstängd" />;
  }

  if (permission === 'prompt') {
    return;
  }

  if (!position || !user) {
    return <FormattedMessage defaultMessage="Söker efter position..." />;
  }

  return (
    <>
      {locationPropertyToString(
        [position.longitude, position.latitude],
        user.projection,
      )}
      <span> ± {accuracy}m</span>
    </>
  );
}
