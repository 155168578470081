import {
  IMobilixApiClient,
  MobilixApiClient,
} from '@allbin/mobilix-api-client';
import { useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useConfig } from './useConfig';
import { useNavigate } from '@tanstack/react-router';

export const mock = {
  mock: false,
};

export const useApiClient = (): IMobilixApiClient => {
  const { baseUrl } = useConfig();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  return useMemo(() => {
    return MobilixApiClient({
      baseUrl,
      token: async () => {
        try {
          return await getAccessTokenSilently({
            authorizationParams: {
              scope: 'openid email profile offline_access',
            },
          });
        } catch (err) {
          navigate({ to: '/login' });
          throw err;
        }
      },
    });
  }, [baseUrl, getAccessTokenSilently, navigate]);
};

export const usePublicApiClient = (): IMobilixApiClient => {
  const { baseUrl } = useConfig();

  return useMemo(() => {
    return MobilixApiClient({
      baseUrl,
      token: undefined,
    });
  }, [baseUrl]);
};
