import { useAuth0 } from '@auth0/auth0-react';
import { useUsers } from '../hooks/useUsers';
import { FormattedMessage } from 'react-intl';

export function UserName({ id }: { id?: string }) {
  const { user } = useAuth0();
  const users = useUsers(id ? [id] : []);

  if (!id) {
    return <FormattedMessage defaultMessage="Okänd" />;
  }

  if (id === user?.sub) {
    return <FormattedMessage defaultMessage="Jag" />;
  }

  if (users.isLoading) {
    return (
      <span className="mr-2 inline-block h-3 w-24 animate-pulse rounded bg-gray-300"></span>
    );
  }

  if (!users.data) {
    return id;
  }

  return <>{users.data[0].name}</>;
}
