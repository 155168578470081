import { ImagePickerContext } from '@/components/EntityProp/ImagePickerProvider';
import { useContext } from 'react';

export function useImagePicker() {
  const { setOpen, resetImages } = useContext(ImagePickerContext);

  return {
    setOpen,
    resetImages,
  };
}
