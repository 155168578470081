import { useAuth0 } from '@auth0/auth0-react';
import { useApiClient } from './useApiClient';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { reviveMetadata } from '../utils/revivers';
import {
  ApiWorkOrderEvent,
  ApiWorkOrderEventClientRequest,
  ApiWorkOrderEventRequest,
} from '@allbin/mobilix-api-client';
import { Metadata } from '../types/metadata';

export type WorkOrderEvent = ApiWorkOrderEventRequest & {
  id: string;
  meta: Metadata;
  sourceType: 'workorder';
};

export interface Asset {
  base64?: string;
  uri?: string;
  width?: number;
  height?: number;
  fileSize?: number;
  type?: string;
  fileName?: string;
  duration?: number;
  bitrate?: number;
  timestamp?: string;
  id?: string;
}

export function useWorkOrderEvents(workorderId: string) {
  const { isAuthenticated } = useAuth0();
  const client = useApiClient();

  return useQuery({
    queryKey: ['workOrders', 'events', workorderId],
    queryFn: async () => {
      try {
        const events = await client.workOrders.listEvents(workorderId);
        return events.map(reviveWorkOrderEvent);
      } catch (error) {
        console.error('Error fetching workorder events', error);
        throw error;
      }
    },
    enabled: isAuthenticated,
  });
}

export function useWorkOrderEvent(workorderId: string, eventId: string) {
  const events = useWorkOrderEvents(workorderId);
  return {
    ...events,
    data: events.data?.find((event) => event.id === eventId),
  };
}

const reviveWorkOrderEvent = (
  workOrderEvent: ApiWorkOrderEvent,
): WorkOrderEvent => ({
  ...workOrderEvent,
  sourceType: 'workorder',
  meta: reviveMetadata(workOrderEvent.meta),
});

interface CommentEvent {
  event: ApiWorkOrderEventClientRequest;
  asset?: File;
}

export function useWorkOrderCreateComment(workOrderId: string) {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['workOrders', 'createEvent', workOrderId],
    mutationFn: ({ event, asset }: CommentEvent) =>
      apiClient.workOrders
        .createEvent(workOrderId, event, asset ? [asset] : undefined)
        .then(reviveWorkOrderEvent),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['workOrders', 'events', workOrderId],
      }),
  });
}
