import MobilixLogo from '@/components/Logo';
import { Spinner } from '@/components/Spinner';
import { useAuth0 } from '@auth0/auth0-react';
import { createFileRoute } from '@tanstack/react-router';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

export const Route = createFileRoute('/logout')({
  component: Logout,
});

function Logout() {
  const { logout } = useAuth0();
  useEffect(() => {
    logout({
      logoutParams: {
        returnTo: window.location.origin + '/login',
      },
    });
  }, [logout]);

  return (
    <div className="flex flex-col items-center gap-8 p-8 sm:gap-16 sm:p-16">
      <MobilixLogo />
      <Spinner />
      <p>
        <FormattedMessage defaultMessage="Loggar ut..." />
      </p>
    </div>
  );
}
