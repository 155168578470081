import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { PageHeader } from '../../../components/PageHeader';
import { FormattedMessage } from 'react-intl';
import {
  useCheckInPlans,
  CheckInPlan,
  ExecutionStatus,
} from '@/hooks/useCheckIn';
import { cn } from '@/lib/utils';
import { ListButton } from '@/components/ListButton';
import { IconArrowRight, IconEye } from '@allbin/icons';

export const Route = createFileRoute('/_layout/checkins/')({
  component: CheckIns,
});

function CheckIns() {
  const checkins = useCheckInPlans();
  return (
    <>
      <PageHeader
        title={<FormattedMessage defaultMessage="Tillsynsplaner" />}
      />
      {checkins.data?.length === 0 && (
        <div>
          <i>
            <FormattedMessage defaultMessage="Inga tillsynsplaner hittades." />
          </i>
        </div>
      )}
      <div className="flex flex-col gap-2">
        {checkins.data?.map((plan) => (
          <CheckInPlanItem key={plan.id} plan={plan} />
        ))}
      </div>
    </>
  );
}

function CheckInPlanItem({ plan }: { plan: CheckInPlan }) {
  const navigate = useNavigate();

  return (
    <ListButton
      onClick={() =>
        navigate({
          to: '/checkins/$checkinId',
          params: { checkinId: plan.id },
        })
      }
      columnLeft={
        <div className="flex flex-col items-start">
          <div className="mb-2 flex items-center gap-2">
            <IconEye className="size-4" />
            <h4 className="text-text-700">{plan.title}</h4>
          </div>
          <span className="flex items-center gap-2 text-sm text-text-600">
            {plan.activePeriod.interval.start?.toISODate()}
            {<IconArrowRight className="size-4" />}
            {plan.activePeriod.occurrence.toISODate()}
          </span>
        </div>
      }
      columnRight={
        <>
          <StateIndicator state={plan.executionStatus} />
          <span className="mt-2 text-sm text-text-600">
            <FormattedMessage
              defaultMessage="{count, plural, =0 {Inga hållplatslägen} =1 {# Hållplatsläge} other {# Hållplatslägen}}"
              values={{
                count: plan.entities.length,
              }}
            />
          </span>
        </>
      }
    />
  );
}

interface StateIndicatorProps {
  state: ExecutionStatus;
}

function StateIndicator({ state }: StateIndicatorProps) {
  return (
    <div
      className={cn(
        'flex h-8 w-28 items-center justify-center rounded-full px-3 py-1.5 text-sm font-medium text-white',
        state === 'loading' && 'animate-pulse bg-gray-200',
        state === 'unknown' && 'bg-gray-400',
        state === 'on_time' && 'bg-green-500',
        state === 'late' && 'bg-yellow-500',
        state === 'missed' && 'bg-red-500',
      )}
    >
      {state === 'unknown' && <FormattedMessage defaultMessage="Okänd" />}
      {state === 'on_time' && <FormattedMessage defaultMessage="I tid" />}
      {state === 'late' && <FormattedMessage defaultMessage="Försenad" />}
      {state === 'missed' && <FormattedMessage defaultMessage="Missad" />}
    </div>
  );
}
