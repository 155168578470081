import { useAuth0 } from '@auth0/auth0-react';
import { useApiClient } from '../hooks/useApiClient';
import { useQuery } from '@tanstack/react-query';

export function useEntityTypes() {
  const { isAuthenticated } = useAuth0();
  const client = useApiClient();

  return useQuery({
    queryKey: ['entity_types', 'list'],
    queryFn: client.entityTypes.list,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated,
  });
}
