import { cn } from '@/lib/utils';
import React from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

interface GuideTextParagraph {
  type: 'text';
  title?: string;
  text?: string;
}

type GuideParagraph = GuideTextParagraph;

interface GuideEntry {
  title: string;
  paragraphs: GuideParagraph[];
}

export function Help() {
  const [selectedGuide, setSelectedGuide] = useState(0);

  return (
    <div className="flex h-full flex-1 overflow-hidden">
      <nav className="flex min-w-36 flex-col gap-4 overflow-auto rounded bg-background-100 p-4">
        <h4>
          <FormattedMessage defaultMessage="Ämnen" />
        </h4>
        <ul className="flex flex-col">
          {guideTexts.map((entry, i) => (
            <li key={entry.title}>
              <button
                className={cn(
                  'flex w-full items-center gap-1 px-2 py-1 text-left text-primary-600',
                  selectedGuide === i &&
                    'rounded bg-primary-100 text-primary-800',
                )}
                onClick={() => setSelectedGuide(i)}
              >
                {entry.title}
              </button>
            </li>
          ))}
        </ul>
      </nav>
      <article className="prose max-w-full overflow-auto px-4 text-justify">
        {guideTexts[selectedGuide].paragraphs.map((paragraph, index) => (
          <React.Fragment key={index}>
            {paragraph.title && <h3>{paragraph.title}</h3>}
            {paragraph.text}
          </React.Fragment>
        ))}
      </article>
    </div>
  );
}

const guideTexts: GuideEntry[] = [
  {
    title: 'Överblick',
    paragraphs: [
      {
        type: 'text',
        title: 'Överblick',
        text: `I överblicken ser man de arbetsordrar som är tilldelade användaren av appen. Tryck direkt på någon av dem för att öppna dem. Under listan med arbetsordrar finns direkt länkar till listor med alla arbetsordrar, hållplatser och tillsynsplaner.`,
      },
    ],
  },
  {
    title: 'Menyrad',
    paragraphs: [
      {
        type: 'text',
        title: 'Menyrad',
        text: `I nederkanten av skärmen ligger menyraden där man kan navigera mellan appens olika delar. Här finns direkt länkar till Överblicken, Arbetsordrarna, Hållplatser, Tillsynsplaner, samt menyval och en direktlänk för att skapa en ny arbetsorder. (Länkar till allt)`,
      },
    ],
  },
  {
    title: 'Arbetsordrar',
    paragraphs: [
      {
        type: 'text',
        title: 'Listvy',
        text: `Här ser du alla arbetsordrar som tillhör din organisation. Varje order innehåller information om ”Titel”, ”Utförare”, Slutdatum” samt ”Status”. De är listade i statusordning, med ”Skapade” först, följt av ”Acceptera”, ”Färdigställda”, ”Godkända” och slutligen ”Avbrutna”. Inom varje status är de listade efter slutdatum. Tryck på en arbetsorder för att gå till dess detaljvy. Om man trycker på kartsymbolen i övre högra hörnet byter man till kartvyn.`,
      },
      {
        type: 'text',
        title: 'Kartvy',
        text: 'I kartvyn syns alla hållplatser där det  ligger en arbetsorder. Trycker man på en hållplatssymbol får man upp information om dess namn samt arbetsordrar. Man kan panorera kartan genom att pressa och dra med ett finger samt zooma genom genom att ”knipa” med två fingrar. I nederkant av kartan finns knappen ”Visa alla” som man trycker på för att zooma ut och se alla hållplatser igen. Trycker man på jordglobssymbolen kan man ändra utseende på kartan. Trycker man på listsymbolen kommer man till listvyn. ',
      },
      {
        type: 'text',
        title: 'Skapa ny arbetsorder',
        text: `Tryck på knappen ”Ny order” för att skapa en ny arbetsorder. Här fyller man i en tydligt titel, slutdatum och arbetsinstruktioner, samt söker upp de hållplatslägen arbetsordern gäller. Sedan trycker man ”Skapa order” för att slutförda.`,
      },
      {
        type: 'text',
        title: 'Listvy',
        text: `I arbetsorderdetaljerna kan man se information om arbetsordern samt interagera med den. Här finns information som ”Titel”, ”Slutdatum”, ”Utförare”, ”Status”, ”Taggar” samt ”Hållplatser”.
I nederdelen av skärmen finns två knappar: ”Acceptera ordern” och ”Händelser”.
Trycker man på ”Acceptera ordern” accepter man att ordern tillhör en själv. När en arbetsorder är accepterade till en själv så får man även alternativen ”Återlämna ordern” för att avsäga sig ordern igen, ”Blockerad” som man trycker på om man av någon anledning inte kan utföra ordern så som det ska göras samt ”Skicka in” som används när ma har utfört arbetet och skickar in det till organisationen.
Trycker man ”Händelser” ser man all aktivitet som skett på den aktuella ordern. I övre högra hörnet finns även en knapp för att lägga till ny kommentar på arbetsordern.`,
      },
      {
        type: 'text',
        title: 'Kommentera arbetsorder',
        text: `Här skriver man in en kommentar angående den aktuella arbetsordern. Man kan även lägga till en bild, antingen från läsplattans galleri eller ta en bild direkt med enhetens kamera.
Trycker man på knappen vid en hållplats så får man en lista med all attribut på det hållplatsläget. Trycket ”Stäng” för att gå tillbaka till arbetsorderdetaljerna.`,
      },
    ],
  },
  {
    title: 'Hållplatser',
    paragraphs: [
      {
        type: 'text',
        title: 'Listvy',
        text: `I listvyn kan man se alla hållplatser i en alfabetisk list. Till vänster i varje rad finns knappar med varje hållplats individuella lägen. Man trycker på hållplatslägesknapparna för att gå till detaljerna om desamma. I toppen kan man även söka för att filtrera ut hållplatser i listan. I övre högra hörnet finns en kartsymbol som tar dig till kartvyn.`,
      },
      {
        type: 'text',
        title: 'Kartvy',
        text: `I kartvyn visas alla hållplatser på en karta. Om flera hållplatser finns inom en litet område grupperas dessa ihop under en punkt med ett nummer i. Numret visar hur många hållplatslägen som finns inom det området. Trycker man på dess punkter zoomas man in. När kartan är så pass zoomad att man kan se individuella hållplatslägen kan man trycka på dem för att få upp deras namn. Trycker man på hållplatslägets namn tas man till dess detaljer. I nederkant av kartan finns knappen ”Visa alla” som man trycker på för att zooma ut och se alla hållplatser igen.`,
      },
      {
        type: 'text',
        title: 'Hållplatsdetaljer',
        text: `I hållplatsdetaljer finns en lista med all attribut från ett specifikt hållplatsläge. I över högra hörnet visas när senaste tillsynen gjordes på hållplatsläget. I nederkant finns fyra knappar: ”Byt läge” som används för att byta läge inom den aktuella hållplatsen, ”Felanmälan” där man kan skicka in en felanmälan det aktuella hållplatsläget genom att …. , ”Gör tillsyn” där man kan markera att en tillsyn gjorts samt skapa arbetsorder eller felanmäla, och slutligen ”Händelser” där man ser all aktivitet som skett på det aktuella hållplatsläget.`,
      },
    ],
  },
  {
    title: 'Tillsyn',
    paragraphs: [
      {
        type: 'text',
        title: 'Tillsyn',
        text: `Under tillsynstabben finns en lista med tilldelade tillsynsplaner. I listan visas varje tillsynsplans titel, mellan vilka datum den ska utföras samt dess status. Statusen kan vara röd (alla tillsyner gjordes inte inom satt tid), gul (tillsynsplanen är öppen för tillsyn just nu) eller grön (alla tillsyner gjordes inom satt tid). Klickar man på en tillsynsplan kommer man till tillsynsdetaljerna.`,
      },
      {
        type: 'text',
        title: 'Tillsynsdetaljer',
        text: `I tillsyn detaljerna ser man de hållplatslägen som tillsynen gäller. De är listade i kvarvarande respektive utförda tillsyner. Till vänster om varje hållplatslägen finns en knapp för att antingen göra tillsyn första gången, eller göra om den. Klickar man på en av de knapparna får man en ruta där man väljer mellan att markera att tillsyn är gjord utan anmärkning, eller direktlänk för att skapa en arbetsorder på det hållplatsläget eller felanmälan hållplatsläget.
I övre högra hörnet finns även två knappar. Via kartsymbolen kan man se de hållplatslägen som ingår i tillsynsplanen på en karta, och med knappen med en pil uppåt öppnas Google Maps och visar den enklaste rutten från din position till de hållplatslägen som finns listade i tillsynsplanen.`,
      },
    ],
  },
  {
    title: 'Meny',
    paragraphs: [
      {
        type: 'text',
        title: 'Meny',
        text: `Under menyn finns två alternativ: Hjälp och Logga ut. Under hjälp hittar man dessa hjälptexter, samt en knapp för att skicka ett mail till supporten om man har ytterligare frågor. Trycker man logga ut så loggas man ut och appen stängs ner.`,
      },
    ],
  },
];
