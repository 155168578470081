import { FormattedMessage } from 'react-intl';
import Button from './core/Button';
import { ReactNode } from 'react';

interface ErrorPageProps {
  title?: ReactNode;
  error?: ReactNode;
}

export function ErrorPage({ title, error }: ErrorPageProps) {
  return (
    <div className="my-auto flex size-full flex-col items-center justify-center gap-4">
      <h2>{title || <FormattedMessage defaultMessage="Ett fel uppstod" />}</h2>
      {error}
      <div className="flex gap-4">
        <Button onClick={() => window.history.back()} className="mt-8">
          <FormattedMessage defaultMessage="Tillbaka" />
        </Button>
        <Button onClick={() => window.location.reload()} className="mt-8">
          <FormattedMessage defaultMessage="Ladda om" />
        </Button>
      </div>
    </div>
  );
}
