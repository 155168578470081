import { ReactNode } from 'react';

interface Props {
  title?: ReactNode;
  subtitle?: ReactNode;
  action?: ReactNode;
  footer?: ReactNode;
}

export function PageHeader({ title, subtitle, action, footer }: Props) {
  return (
    <header className="sticky top-0 z-[999] flex flex-col gap-4">
      <div className="mb-4 flex flex-col justify-between border-b border-b-background-100 bg-background-50 py-4 sm:flex-row sm:items-end">
        <div className="flex flex-col gap-2 sm:mb-0">
          {title && <h2>{title}</h2>}
          {subtitle && <span>{subtitle}</span>}
        </div>
        {action}
      </div>
      {footer}
    </header>
  );
}
