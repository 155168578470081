import { Control } from 'react-hook-form';
import { EntityProperty } from './EntityProp';
import { FormValues } from './EntityPropertyList';
import { useMemo } from 'react';
import { DateInput } from './inputs/DateInput';
import { LocationInput } from './inputs/LocationInput';
import { ImageInput } from './inputs/ImageInput';
import { EnumInput } from './inputs/EnumInput';
import { StringInput } from './inputs/StringInput';
import { BooleanInput } from './inputs/BooleanInput';
import { NumberInput } from './inputs/NumberInput';
import { ArrayStringInput } from './inputs/ArrayStringInput';
import { ImageRowProps } from './EntityPropertyList';
import { UseFormSetValue } from 'react-hook-form';

interface EntityPropertyFormParams {
  property: EntityProperty;
  control: Control<FormValues>;
  images?: ImageRowProps[];
  setValue?: UseFormSetValue<FormValues>;
}

export function EntityPropertyForm({
  control,
  property,
  setValue,
}: EntityPropertyFormParams) {
  const name = useMemo(() => property.key.replace('.', '#'), [property.key]);

  switch (property.type) {
    case 'boolean':
      return <BooleanInput control={control} name={name} />;
    case 'enum':
      return (
        <EnumInput
          control={control}
          name={name}
          options={property.alternatives || []}
        />
      );
    case 'string':
      return <StringInput control={control} name={name} />;
    case 'array:string':
      return <ArrayStringInput control={control} name={name} />;
    case 'number':
      return <NumberInput control={control} name={name} />;
    case 'photo':
      return (
        <ImageInput
          control={control}
          name={name}
          setValue={setValue as UseFormSetValue<FormValues>}
        />
      );
    case 'location':
      return <LocationInput control={control} name={name} />;
    case 'date':
      return <DateInput control={control} name={name} />;
    default:
      console.error('Unknown type', property.type);
      return (
        <div className="border-2 border-orange-200 bg-red-500 p-2">
          Edit {property.name}
        </div>
      );
  }
}
