import { useMutation } from '@tanstack/react-query';
import { useApiClient } from './useApiClient';

export function useCreateAttachment() {
  const client = useApiClient();

  return useMutation({
    mutationKey: ['attachments', 'create'],
    mutationFn: (file: File) => client.attachments.create(file),
  });
}
