import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { IconXmark } from '@allbin/icons';
import IconButton from './core/IconButton';
import Button from './core/Button';
import { cn } from '@/utils/classnames';

type ToggleProps = TogglePropsIndeterminate | TogglePropsDeterminate;

interface TogglePropsDeterminate {
  value: boolean;
  onChange: (value: boolean) => void;
  indeterminate?: never;
}

interface TogglePropsIndeterminate {
  value: boolean | null;
  onChange: (value: boolean | null) => void;
  indeterminate: true;
}

export function SelectButtonGroup({
  value,
  onChange,
  indeterminate,
}: ToggleProps) {
  const changeState = useCallback(
    (newState: boolean | null) => {
      if (indeterminate) {
        onChange(newState);
      }
    },
    [indeterminate, onChange],
  );

  return (
    <div className="isolate inline-flex rounded-md">
      {value === null && (
        <i className="mr-2 flex items-center text-sm text-text-600">
          <FormattedMessage defaultMessage="Inget värde" />
        </i>
      )}
      {indeterminate && value !== null && (
        <IconButton
          variant="ghost"
          round
          icon={<IconXmark />}
          className="mr-2"
          onClick={() => changeState(null)}
        />
      )}
      <Button
        variant={value === true ? 'filled' : 'outline'}
        color={value === true ? 'green' : 'base'}
        className="rounded-r-none border-r-0"
        onClick={() => changeState(true)}
      >
        <FormattedMessage defaultMessage="Ja" />
      </Button>
      <Button
        variant={value === false ? 'filled' : 'outline'}
        color={value === false ? 'red' : 'base'}
        className={cn('rounded-l-none', value === true && 'border-l-0')}
        onClick={() => changeState(false)}
      >
        <FormattedMessage defaultMessage="Nej" />
      </Button>
    </div>
  );
}
