import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import IconButton from '@/components/core/IconButton';
import { useEntities } from '../hooks/useEntities';
import { Spinner } from '../components/Spinner';
import { useEffect, useMemo, useState } from 'react';
import { collator } from '../utils/collator';
import { ErrorPage } from '../components/ErrorPage';
import { IconList, IconMap, IconSearch, IconXmark } from '@allbin/icons';
import Input from '@/components/core/Input';
import Fuse from 'fuse.js';
import { PageHeader } from '../components/PageHeader';
import { z } from 'zod';
import { EntityGroupList } from '../components/EntityGroupList';
import { EntityGroup } from '../types/entities';
import { NavBar } from '../components/NavBar';
import { useDebounce } from '../utils/debounce';
import { EntityMap } from '@/components/EntityMap';

type DisplayMode = 'list' | 'map';

const entitySearchSchema = z.object({
  mode: z.enum(['list', 'map']).default('list').optional(),
});

export const Route = createFileRoute('/entities')({
  component: Entities,
  validateSearch: entitySearchSchema,
});

function Entities() {
  const navigate = useNavigate();
  const { mode } = Route.useSearch();

  const entities = useEntities();

  const entityGroups = useMemo(() => {
    if (!entities.data) {
      return [];
    }

    const groups = entities.data.reduce<Record<string, EntityGroup>>(
      (acc, entity) => {
        if (!acc[entity.entity_group]) {
          acc[entity.entity_group] = {
            ...entity,
            entities: [],
          };
        }
        acc[entity.entity_group].entities.push(entity);
        return acc;
      },
      {},
    );
    return Object.values(groups).sort((a, b) => {
      // sort by name
      return collator.compare(a.full_name, b.full_name);
    });
  }, [entities.data]);

  const fuse = useMemo(
    () =>
      new Fuse(entityGroups, {
        keys: ['full_name', 'entity_group'],
        threshold: 0.0,
      }),
    [entityGroups],
  );

  const [search, setSearch] = useState('');

  const filteredOptions = useMemo(() => {
    if (!search) {
      return entityGroups;
    }

    return fuse
      .search(search)
      .map((item) => item.item)
      .slice(0, 20);
  }, [fuse, entityGroups, search]);

  if (entities.error) {
    return (
      <ErrorPage
        title={
          <FormattedMessage defaultMessage="Kunde inte hämta hållplatser" />
        }
        error={entities.error.message}
      />
    );
  }

  return (
    <div className="flex size-full h-full flex-col items-center">
      <main className="flex max-h-full w-full max-w-full grow flex-col overflow-auto ">
        <div className="px-2 sm:px-8 md:px-16">
          <PageHeader
            title={<FormattedMessage defaultMessage="Hållplatser" />}
            action={
              <div className="flex items-center gap-2">
                <Search value={search} onChange={setSearch} />
                <IconButton
                  round
                  onClick={() =>
                    navigate({
                      search: {
                        mode: mode === 'map' ? 'list' : 'map',
                      },
                    })
                  }
                  icon={mode === 'map' ? <IconList /> : <IconMap />}
                />
              </div>
            }
          />
        </div>
        {entities.isLoading && (
          <div className="flex w-full justify-center">
            <Spinner />
          </div>
        )}
        {!entities.isLoading && !entities.data && (
          <i>
            <FormattedMessage defaultMessage="Det finns inga hållplatser att visa" />
          </i>
        )}
        {!entities.isLoading && filteredOptions && (
          <EntityContent groups={filteredOptions} mode={mode || 'list'} />
        )}
      </main>
      <NavBar />
    </div>
  );
}

interface SearchProps {
  value: string;
  onChange: (value: string) => void;
}

function Search({ value, onChange }: SearchProps) {
  const intl = useIntl();
  const [search, setSearch] = useState(value);
  const debounced = useDebounce(search, 300);

  useEffect(() => {
    onChange(debounced);
  }, [debounced, onChange]);

  return (
    <Input
      id="entities"
      placeholder={intl.formatMessage({
        defaultMessage: 'Sök efter hållplats',
      })}
      value={search}
      onChange={(event) => {
        setSearch(event.target.value);
      }}
      startAdornment={<IconSearch className="size-4" />}
      endAdornment={
        search && (
          <button onClick={() => setSearch('')}>
            <IconXmark className="size-4" />
          </button>
        )
      }
    />
  );
}

interface EntityContentProps {
  mode: DisplayMode;
  groups: EntityGroup[];
}

function EntityContent({ mode, groups }: EntityContentProps) {
  if (mode === 'map') {
    return <EntityMap groups={groups} />;
  }
  return <EntityGroupList groups={groups} />;
}
