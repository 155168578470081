import { ApiWorkOrderState } from '@allbin/mobilix-api-client';
import { FormattedMessage } from 'react-intl';

interface StateNameProps {
  state: ApiWorkOrderState;
}

export function StateName({ state }: StateNameProps) {
  switch (state) {
    case 'created':
      return <FormattedMessage defaultMessage="Skapad" />;
    case 'blocked':
      return <FormattedMessage defaultMessage="Blockerad" />;
    case 'accepted':
      return <FormattedMessage defaultMessage="Accepterad" />;
    case 'completed':
      return <FormattedMessage defaultMessage="Färdig" />;
    case 'rejected':
      return <FormattedMessage defaultMessage="Avvisad" />;
    case 'approved':
      return <FormattedMessage defaultMessage="Godkänd" />;
    case 'cancelled':
      return <FormattedMessage defaultMessage="Avbruten" />;
    default:
      return <FormattedMessage defaultMessage="Okänd" />;
  }
}
