import Button from '@/components/core/Button';
import MobilixLogo from '@/components/Logo';
import { useAuth0 } from '@auth0/auth0-react';
import { Link, Navigate, createFileRoute } from '@tanstack/react-router';
import { FormattedMessage } from 'react-intl';

export const Route = createFileRoute('/callback')({
  component: Callback,
});

function Callback() {
  const { isLoading, isAuthenticated } = useAuth0();

  if (isLoading) {
    return (
      <div className="flex flex-col items-center gap-8 p-8 sm:gap-16 sm:p-16">
        <MobilixLogo />
        <p>
          <FormattedMessage defaultMessage="Loggar in..." />
        </p>
      </div>
    );
  }

  if (isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="flex flex-col items-center gap-8 p-8 sm:gap-16 sm:p-16">
      <MobilixLogo />
      <p>
        <FormattedMessage defaultMessage="Kunde inte logga in" />
      </p>
      <Link to="/login">
        <Button>
          <FormattedMessage defaultMessage="Försök igen" />
        </Button>
      </Link>
    </div>
  );
}
