import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

interface showAttributesState {
  showEditAttributes: boolean;
  toggleAttributes: () => void;
  showTheme: string;
  toggleTheme: (theme: string) => void;
}

export const useShowAttributes = create<showAttributesState>()(
  persist(
    (set, get) => ({
      showEditAttributes: true,
      showTheme: 'default',
      toggleAttributes: () =>
        set({ showEditAttributes: !get().showEditAttributes }),
      toggleTheme: (theme: string) => set({ showTheme: theme }),
    }),
    {
      name: 'mobilix-showAttributes',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
