import { useMutation, useQuery } from '@tanstack/react-query';
import { useApiClient } from './useApiClient';
import {
  ApiErrorReport,
  ApiErrorReportRequest,
} from '@allbin/mobilix-api-client';
import { reviveMetadata } from '@/utils/revivers';
import { Metadata } from '@/types/metadata';

export const reviveErrorReport = (
  errorReport: ApiErrorReport,
): ErrorReport => ({
  ...errorReport,
  meta: reviveMetadata(errorReport.meta),
});

export interface ErrorReport extends Omit<ApiErrorReport, 'meta'> {
  meta: Metadata;
}

export function useErrorReports(entityId: string) {
  const client = useApiClient();

  return useQuery({
    queryKey: ['errorReports', entityId],
    queryFn: async () => {
      const result = await client.errorReports.list(entityId);
      return result.map(reviveErrorReport);
    },
  });
}

export const useCreateErrorReport = () => {
  const client = useApiClient();

  return useMutation({
    mutationKey: ['errorReports', 'create'],
    mutationFn: (errorReport: ApiErrorReportRequest) =>
      client.errorReports.create(errorReport),
  });
};
