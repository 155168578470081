// import { Toggle } from '@/components/toggle';
import { SelectButtonGroup } from '@/components/SelectButtonGroup';
import { UseControllerProps, useController } from 'react-hook-form';
import { FormValues } from '../EntityPropertyList';

export function BooleanInput(props: UseControllerProps<FormValues>) {
  const { field } = useController(props);

  return (
    <SelectButtonGroup
      indeterminate
      value={field.value as boolean | null}
      onChange={field.onChange}
    />
  );
}
